//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	sass 3.4
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :  
@import '../dc7/__dc7.scss';

 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : config
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : config - vars
$om3__b_init: false;			///h :
$om3__map_data: null;			///h :

$om3__map_config: () !default;
$om3__map_config: map-merge($om3__map_config, ('system__b_init':true)) !default;
$om3__map_config: map-merge($om3__map_config, ('animation__map_ease':('Expo.easeIn': 'cubic-bezier(0.950,  0.050, 0.795, 0.035)', 'Expo.easeOut': 'cubic-bezier(0.190,  1.000, 0.220, 1.000)', 'Expo.easeInOut': 'cubic-bezier(1.000,  0.000, 0.000, 1.000)', 'Circ.easeIn': 'cubic-bezier(0.600,  0.040, 0.980, 0.335)', 'Circ.easeOut': 'cubic-bezier(0.075,  0.820, 0.165, 1.000)', 'Circ.easeInOut': 'cubic-bezier(0.785,  0.135, 0.150, 0.860)', 'Quad.easeIn': 'cubic-bezier(0.550,  0.085, 0.680, 0.530)', 'Quad.easeOut': 'cubic-bezier(0.250,  0.460, 0.450, 0.940)', 'Quad.easeInOut': 'cubic-bezier(0.455,  0.030, 0.515, 0.955)'))) !default;


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : methods - default
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : transform - init system


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@mixin om3__m_initSystem()
	{ 
	///h : check if system is initiated
	///h :
	@if($om3__b_init != true)
		{
		///h :
		@include om3__m_init();

		///h :
		$om3__b_init: true !global;
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@mixin om3__m_init()
	{
	///h : init data
	@include om3__m_initData();

	///h : init content
	@include om3__m_initContent();
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@mixin om3__m_initData()
	{
	///h :
	$om3__map_data:
		(
		)!global !default;
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@mixin om3__m_initContent()
	{
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@mixin om3__m_initConfig($map_config)
	{
	}
	
	
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@mixin om3__m_addScrollRevealFX($s_id:'fade')
	{
	///h :
	#{'.om3__segment.dc7__behavior__scrollreveal.__s_scrollreveal__#{$s_id}'}
		{
		///h :
		&.dc7__behavior__scrollreveal__in
			{
			///h :
			@include dc7__fx__m_preset($s_id:$s_id, $s_direction:'in', $s_duration:'0.8s', $s_delay:'0.10s');
			}
		///h :
		&.dc7__behavior__scrollreveal__out
			{
			///h :
			@include dc7__fx__m_preset($s_id:$s_id , $s_direction:'out', $s_duration:'0.8s', $s_delay:'0.00s');
			} 
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@mixin om3__m_embedType()
	{
	///h :
	$list_id: null;		///h :
	$i_data: null;		///h :
	$i_loop: null;		///h :
	$s_preset_id: null;	///h :
	
	///h :
	strong,
	b,
	p > strong,
	p > b
		{
		font-weight: #{$host__type__weight__bold};
		}

	///h : default
	@include dc7__type__m_font($s_preset_id:'default', $s_selector__base:'.content')
		{
		///h : 
		@include dc7__type__m_fontlayout($s_family:$host__type__p1__s_family, $s_size:$host__type__p1__s_size, $s_color:$host__type__p1__s_color, $s_lineheight:$host__type__p1__s_lineheight, $s_weight:$host__type__p1__s_weight, $s_letterspacing:$host__type__p1__s_letterspacing, $s_style:$host__type__p1__s_style, $s_transform:$host__type__p1__s_transform, $map_breakpoints:(sma:0.80, tab:0.80, des:1.0));
		}

	///h : p1
	@include dc7__type__m_font($s_preset_id:'p1', $s_selector__base:'.content')
		{
		///h :  
		@include dc7__type__m_fontlayout($s_family:$host__type__p1__s_family, $s_size:$host__type__p1__s_size, $s_color:$host__type__p1__s_color, $s_lineheight:$host__type__p1__s_lineheight, $s_weight:$host__type__p1__s_weight, $s_letterspacing:$host__type__p1__s_letterspacing, $s_style:$host__type__p1__s_style, $s_transform:$host__type__p1__s_transform, $map_breakpoints:(sma:0.80, tab:0.80, des:1.0));
		}

	///h : p2 
	@include dc7__type__m_font($s_preset_id:'p2', $s_selector__base:'.content')
		{
		///h : 
		@include dc7__type__m_fontlayout($s_family:$host__type__p2__s_family, $s_size:$host__type__p2__s_size, $s_color:$host__type__p2__s_color, $s_lineheight:$host__type__p2__s_lineheight, $s_weight:$host__type__p2__s_weight, $s_letterspacing:$host__type__p2__s_letterspacing, $s_style:$host__type__p2__s_style, $s_transform:$host__type__p2__s_transform, $map_breakpoints:(sma:0.5, tab:0.8, des:1.0));
		}
 
	///h : p3
	@include dc7__type__m_font($s_preset_id:'p3', $s_selector__base:'.content')
		{
		///h : 
		@include dc7__type__m_fontlayout($s_family:$host__type__p3__s_family, $s_size:$host__type__p3__s_size, $s_color:$host__type__p3__s_color, $s_lineheight:$host__type__p3__s_lineheight, $s_weight:$host__type__p3__s_weight, $s_letterspacing:$host__type__p3__s_letterspacing, $s_style:$host__type__p3__s_style, $s_transform:$host__type__p3__s_transform, $map_breakpoints:(sma:0.5, tab:0.8, des:1.0));
		}
		
	///h : p3
	@include dc7__type__m_font($s_preset_id:'p4', $s_selector__base:'.content')
		{
		///h : 
		@include dc7__type__m_fontlayout($s_family:$host__type__p4__s_family, $s_size:$host__type__p4__s_size, $s_color:$host__type__p4__s_color, $s_lineheight:$host__type__p4__s_lineheight, $s_weight:$host__type__p4__s_weight, $s_letterspacing:$host__type__p4__s_letterspacing, $s_style:$host__type__p4__s_style, $s_transform:$host__type__p4__s_transform, $map_breakpoints:(sma:0.5, tab:0.8, des:1.0));
		}
		
	///h : p3
	@include dc7__type__m_font($s_preset_id:'p5', $s_selector__base:'.content')
		{
		///h : 
		@include dc7__type__m_fontlayout($s_family:$host__type__p5__s_family, $s_size:$host__type__p5__s_size, $s_color:$host__type__p5__s_color, $s_lineheight:$host__type__p5__s_lineheight, $s_weight:$host__type__p5__s_weight, $s_letterspacing:$host__type__p5__s_letterspacing, $s_style:$host__type__p5__s_style, $s_transform:$host__type__p5__s_transform, $map_breakpoints:(sma:0.5, tab:0.8, des:1.0));
		}
		
	///h : p3
	@include dc7__type__m_font($s_preset_id:'p6', $s_selector__base:'.content')
		{
		///h : 
		@include dc7__type__m_fontlayout($s_family:$host__type__p6__s_family, $s_size:$host__type__p6__s_size, $s_color:$host__type__p6__s_color, $s_lineheight:$host__type__p6__s_lineheight, $s_weight:$host__type__p6__s_weight, $s_letterspacing:$host__type__p6__s_letterspacing, $s_style:$host__type__p6__s_style, $s_transform:$host__type__p6__s_transform, $map_breakpoints:(sma:0.5, tab:0.8, des:1.0));
		}
 
	///h : h1
	@include dc7__type__m_font($s_preset_id:'h1', $s_selector__base:'.content')
		{
		///h : 
		@include dc7__type__m_fontlayout($s_family:$host__type__h1__s_family, $s_size:$host__type__h1__s_size, $s_color:$host__type__h1__s_color, $s_lineheight:$host__type__h1__s_lineheight, $s_weight:$host__type__h1__s_weight, $s_letterspacing:$host__type__h1__s_letterspacing, $s_style:$host__type__h1__s_style, $s_transform:$host__type__h1__s_transform, $map_breakpoints:(sma:0.4, tab:0.8, des:1.0));
		}

	///h : h2
	@include dc7__type__m_font($s_preset_id:'h2', $s_selector__base:'.content')
		{
		///h : 
		@include dc7__type__m_fontlayout($s_family:$host__type__h2__s_family, $s_size:$host__type__h2__s_size, $s_color:$host__type__h2__s_color, $s_lineheight:$host__type__h2__s_lineheight, $s_weight:$host__type__h2__s_weight, $s_letterspacing:$host__type__h2__s_letterspacing, $s_style:$host__type__h2__s_style, $s_transform:$host__type__h2__s_transform, $map_breakpoints:(sma:0.5, tab:0.8, des:1.0));
		}

	///h : h3
	@include dc7__type__m_font($s_preset_id:'h3', $s_selector__base:'.content')
		{
		///h : 
		@include dc7__type__m_fontlayout($s_family:$host__type__h3__s_family, $s_size:$host__type__h3__s_size, $s_color:$host__type__h3__s_color, $s_lineheight:$host__type__h3__s_lineheight, $s_weight:$host__type__h3__s_weight, $s_letterspacing:$host__type__h3__s_letterspacing, $s_style:$host__type__h3__s_style, $s_transform:$host__type__h3__s_transform, $map_breakpoints:(sma:0.35, tab:0.8, des:1.0));
		}

	///h : h4
	@include dc7__type__m_font($s_preset_id:'h4', $s_selector__base:'.content')
		{
		///h : 
		@include dc7__type__m_fontlayout($s_family:$host__type__h4__s_family, $s_size:$host__type__h4__s_size, $s_color:$host__type__h4__s_color, $s_lineheight:$host__type__h4__s_lineheight, $s_weight:$host__type__h4__s_weight, $s_letterspacing:$host__type__h4__s_letterspacing, $s_style:$host__type__h4__s_style, $s_transform:$host__type__h4__s_transform, $map_breakpoints:(sma:0.3, tab:0.8, des:1.0));
		}
 
	///h : h5
	@include dc7__type__m_font($s_preset_id:'h5', $s_selector__base:'.content')
		{
		///h : 
		@include dc7__type__m_fontlayout($s_family:$host__type__h5__s_family, $s_size:$host__type__h5__s_size, $s_color:$host__type__h5__s_color, $s_lineheight:$host__type__h5__s_lineheight, $s_weight:$host__type__h5__s_weight, $s_letterspacing:$host__type__h5__s_letterspacing, $s_style:$host__type__h5__s_style, $s_transform:$host__type__h5__s_transform, $map_breakpoints:(sma:0.3, tab:0.8, des:1.0));
		}
 
	///h : h6
	@include dc7__type__m_font($s_preset_id:'h6', $s_selector__base:'.content')
		{
		///h : 
		@include dc7__type__m_fontlayout($s_family:$host__type__h6__s_family, $s_size:$host__type__h6__s_size, $s_color:$host__type__h6__s_color, $s_lineheight:$host__type__h6__s_lineheight, $s_weight:$host__type__h6__s_weight, $s_letterspacing:$host__type__h6__s_letterspacing, $s_style:$host__type__h6__s_style, $s_transform:$host__type__h6__s_transform, $map_breakpoints:(sma:0.4, tab:0.8, des:1.0));
		}
	} 


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@mixin om3__m_embedHostStyles()
	{
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : default values
	$host__links__text__s_color: $host__basic__s_color__1 !default !global;
	$host__links__text__s_color__hover: $host__basic__s_color__1 !default !global;
	$host__links__footer__s_color: $host__basic__s_color__white !default !global;		
	$host__links__footer__s_color__hover: $host__basic__s_color__white !default !global;	


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : basic

	///h :
	body
		{
		background-color: #{$host__body__s_background_color};
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : type

	///h :
	@include om3__m_embedType();


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : menu

	///h : menu - color - background
	.content .content_menu
		{
		///h :
		background-color: #{$host__menu__s_background_color};
		}

	///h : menu - border - color
	.content .content_menu .navbar
		{
		///h :
		border-bottom: 1px solid #{$host__menu__s_border_color};
		}

	///h :
	.content .content_menu .navbar li
		{
		///h :
		@extend #{'.dc7__type__' + $host__menu__s_font_id};

		///h :
		&.normal
			{
			color: #{$host__menu__s_text_color}; 
			}

		///h :
		&.current
			{
			///h :
			color: #{$host__menu__s_text_color__hover};
			}

		///h :
		&:hover
			{
			///h :
			color: #{$host__menu__s_text_color__hover};
			}
		}

	///h :
	.content .content_menu .content_menu_contacticon
		{
		///h :
		color: #{$host__menu__s_contacticon_color};

		///h :
		&:hover
			{
			color: #{$host__menu__s_contacticon_color__hover};
			}
		}

	///h :
	.content .content_menu_burger
		{
		///h :
		color: #{$host__menu__s_burger_color} !important;

		///h :
		&:before
			{
			///h :
			color: #{$host__menu__s_burger_color} !important;
			}

		///h :
		&:hover
			{
			///h :
			color: #{$host__menu__s_burger_color__hover} !important;
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : buttons
	

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : buttons
	
	
	///h :
	.content .om3__segment__button .om3__segment__button_button,
	.content .om3__segment__button.__s_type__a .om3__segment__button_button,
	.content .om3__segment__button.__s_type__b .om3__segment__button_button, 
	.content .om3__segment__button.__s_type__c .om3__segment__button_button, 
	.content .om3__segment__button.__s_type__d .om3__segment__button_button,
	#content .cron-formbuilder-plugin > button,
	.om3__segment__nform button
		{
		///h :
		@extend #{'.dc7__type__' + $host__buttons__b1__s_font_id};
	
		///h :
		background-color: #{$host__buttons__b1__s_background_color};
		color: #{$host__buttons__b1__s_text_color} !important;
		-webkit-appearance: none; 
		-moz-appearance: none;
		border-style: solid;
		border-color: #{$host__buttons__b1__s_border_color};
		border-collapse: collapse;
		border-width: #{$host__buttons__b1__s_border_width};
	
		///h :
		@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.2s', $b_acceleration:false, $s_delay:'0.0s', $s_ease:'easeOutQuad');

		}
	
	///h :
	.content .om3__segment__button.__s_type__a .om3__segment__button_button:hover,
	.content .om3__segment__button.__s_type__a._state__hover__true .om3__segment__button_button,
	.om3__segment__nform button:hover
		{
		///h :
		background-color: #{$host__buttons__b1__s_background_color__hover};
		color: #{$host__buttons__b1__s_text_color__hover} !important;
		border-color: #{$host__buttons__b1__s_border_color__hover};
		}

	///h :
	.content .om3__segment__button.__s_type__b .om3__segment__button_button
		{
		///h :
		@extend #{'.dc7__type__' + $host__buttons__b2__s_font_id};
	
		///h :
		background-color: #{$host__buttons__b2__s_background_color};
		color: #{$host__buttons__b2__s_text_color} !important;
		border-color: #{$host__buttons__b2__s_border_color};
		border-width: #{$host__buttons__b2__s_border_width};
		}
		
	///h :
	.content .om3__segment__button.__s_type__b .om3__segment__button_button:hover,
	.content .om3__segment__button.__s_type__b._state__hover__true .om3__segment__button_button,	
		{
		///h :
		background-color: #{$host__buttons__b2__s_background_color__hover};
		color: #{$host__buttons__b2__s_text_color__hover} !important;
		border-color: #{$host__buttons__b2__s_border_color__hover};
		}

	///h :
	.content .om3__segment__button.__s_type__c .om3__segment__button_button
		{
		///h :
		@extend #{'.dc7__type__' + $host__buttons__b3__s_font_id};
	
		///h :
		background-color: #{$host__buttons__b3__s_background_color};
		color: #{$host__buttons__b3__s_text_color} !important;
		border-color: #{$host__buttons__b3__s_border_color};
		border-width: #{$host__buttons__b3__s_border_width};
		}
		
	///h :
	.content .om3__segment__button.__s_type__c .om3__segment__button_button:hover,
	.content .om3__segment__button.__s_type__c._state__hover__true .om3__segment__button_button,	
		{
		///h :
		background-color: #{$host__buttons__b3__s_background_color__hover};
		color: #{$host__buttons__b3__s_text_color__hover} !important;
		border-color: #{$host__buttons__b3__s_border_color__hover};
		}
		
	///h :
	.content .om3__segment__button.__s_type__d .om3__segment__button_button
		{
		///h :
		@extend #{'.dc7__type__' + $host__buttons__b4__s_font_id};
	
		///h :
		background-color: #{$host__buttons__b4__s_background_color};
		color: #{$host__buttons__b4__s_text_color} !important;
		border-color: #{$host__buttons__b4__s_border_color};
		border-width: #{$host__buttons__b4__s_border_width};
		}
		
	///h :
	.content .om3__segment__button.__s_type__d .om3__segment__button_button:hover,
	.content .om3__segment__button.__s_type__d._state__hover__true .om3__segment__button_button,	
		{
		///h :
		background-color: #{$host__buttons__b4__s_background_color__hover};
		color: #{$host__buttons__b4__s_text_color__hover} !important;
		border-color: #{$host__buttons__b4__s_border_color__hover};
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : icons

	///h :
	html .dc7__behavior__pagescrollup 
		{
		///h :
		&:after
			{
			background-color: #{$host__icons__s_background_color};
			}

		///h :
		& > i
			{
			///h :
			color: #{$host__icons__s_foreground_color};
			}
		}

	///h :
	.content .om3__segment__slider .owl-dot 
		{
		///h :
		border-color: #{$host__icons__s_foreground_color};
		background-color: #{$host__icons__s_background_color};

		///h :
		&.active
			{
			background-color: #{$host__icons__s_foreground_color};
			}
		}

	///h :
	.content .om3__segment__slider .owl-nav .owl-prev i,
	.content .om3__segment__slider .owl-nav .owl-next i,
	.content .dc7__behavior__pagescrollup i
		{
		///h :
		color: #{$host__icons__s_foreground_color};
 
		///h : 
		:after
			{
			background-color: #{$host__icons__s_background_color};
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : footer

	///h :
	.content_footer
		{
		///h :
		background-color: #{$host__footer__s_background_color};
		}

	///h :
	.content .content_footer,
	.content .content_footer p,
	.content .content_footer td
		{
		///h :
		@extend #{'.dc7__type__' + $host__footer__s_font_id};
		color: #{$host__footer__s_font_color} !important;
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : links
	
	///h :
	.content .content_page .om3__segment__text a,
	.content .content_page .om3__segment__html a,
	.content .content_page .om3__segment__magazine_element_content > p > a
		{
		///h :
		color: #{$host__links__text__s_color};
		
		///h :
		@include dc7__animation__m_transition($s_property_name:'color', $s_duration:'0.4s', $b_acceleration:false, $s_delay:'0.0s', $s_ease:'easeOutQuad');
		
		///h :
		&:hover
			{
			///h :
			color: #{$host__links__text__s_color__hover};
			}
		}
	
	}
	

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : styles - body and basic setup
//////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :
@include om3__m_initSystem();


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
 
/*
///h : condition
@include om3__sass__m_checkCondition($s_breakpoint_id:'sma', $s_selector__base:'.content', $s_mediaquery__from:null, $s_mediaquery_to:null, $s_mediaquery__type:only screen, $s_mediaquery_orientation:null, $a_mediaquery_conditions:null)
	{
	color: pink;
	}	
*/
