/// Pad `$string` from `$direction` with `$pad` to reach `$length` characters.
/// @since 1.2.0
/// @param {String} $string           - string to pad
/// @param {Number} $length           - number of characters to go for in returned string
/// @param {String} $pad       (" ")  - string to use to pad `$string`
/// @param {String} $direction (left) - direction left or right for padding
/// @return {String}
@function _ss-str-pad($string, $length, $pad: " ", $direction: left) {
  $new-string: $string;
  $index: 1;

  @if $length > str-length($string) {
    @while str-length($new-string) < $length {
      $remains: $length - str-length($new-string);
      $pad: if($remains < str-length($pad), str-slice($pad, 1, $remains), $pad);

      @if $direction == left {
        $new-string: str-insert($new-string, $pad, $index);
        $index: $index + str-length($pad);
      } @else {
        $new-string: $new-string + $pad;
      }
    }
  }

  @return $new-string;
}
