/// Cast `$literal` to string.
/// @since 1.2.0
/// @param {*} $literal - number to cast to string
/// @return {String}
@function _ss-stringify($literal) {
  $result: "";

  @if length($literal) == 1 {
    $result: $literal + unquote("");
  } @else {
    @each $item in $literal {
      $result: $result + stringify($item);
    }
  }

  @return quote($result);
}
