/// Reverse `$string`.
/// @since 1.2.0
/// @param {String} $string - string to reverse
/// @return {String}
@function _ss-str-reverse($string) {
  $result: "";

  @for $i from str-length($string) through 1 {
    $result: $result + str-slice($string, $i, $i);
  }

  @return $result;
}
