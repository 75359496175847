//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	sass 3.4
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : sassycast - v.1.00 - https://github.com/HugoGiraudel/SassyCast
///h : sass casting variable types and forcing right var type with helper functions.
@import 'sassycast_1.0/_SassyCast.scss';

///h : sassystring - v.1.00 - https://github.com/HugoGiraudel/SassyStrings
///h : sass string handling helper functions like replace, index, trim, explode, word count.
@import 'sassystrings_1.0/_SassyStrings.scss'; 


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : config
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : config - vars
$dc7__b_init: false !global !default;		///h :
$dc7__map_data: null !global !default;		///h :
 
 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : methods - default
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : init
/**
 * init
 * init class calling all default functions like initenvironment, initdata
 * initcontent.
 *
 * @access public
 * @param
 * @return vault
 */
@mixin dc7__m_initSystem()
	{ 
	///h : check if system is initiated
	///h :
	@if($dc7__b_init != true)
		{
		///h :
		@include dc7__m_init();
 
		///h :
		$dc7__b_init: true !global;
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////
///h : init data
/**
 * init data
 * used to init all data later on used in the class context like
 * configuration arrays etc.
 *
 * @access public
 * @param
 * @return vault
 */
@mixin dc7__m_init()
	{
	///h : init data
	@include dc7__m_initData();

	///h : init content
	@include dc7__m_initContent();
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////
///h : init data
/**
 * init data
 * used to init all data later on used in the class context like
 * configuration arrays etc.
 *
 * @access public
 * @param
 * @return vault
 */
@mixin dc7__m_initData()
	{
	///h :
	$dc7__map_data:
		(
		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : system 

		///h :
		system__s_base_selector: '',
		system__color_white: #FFFFFF,
		system__color_black: #111111,
		system__color_grey__lite: #DDDDDD,


		//////////////////////////////////////////////////////////////////////////////////////////////////////
		///h : type

		///h : 
		type__b_embed: true,

		///h :
		type__map_selector:
			(
			base:('html', 'body', 'span', 'select', 'option', 'form', 'fieldset', 'table', 'tr', 'td', 'img', 'textarea', 'input', 'button', 'label', 'li'),
			p1: ('p', '.p1', '.dc7__type__p1'),
			p2: ('.p2', '.dc7__type__p2'),
			p3: ('.p3', '.dc7__type__p3'),
			p4: ('.p4', '.dc7__type__p4'),
			p5: ('.p5', '.dc7__type__p5'),
			p6: ('.p6', '.dc7__type__p6'),
			h1: ('h1', '.h1', '.dc7__type__h1'),
			h2: ('h2', '.h2', '.dc7__type__h2'),
			h3: ('h3', '.h3', '.dc7__type__h3'),
			h4: ('h4', '.h4', '.dc7__type__h4'),
			h5: ('h5', '.h5', '.dc7__type__h5'),
			h6: ('h6', '.h6', '.dc7__type__h6')
			),


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : grid 

		///h :
		grid__b_embed: true,
		grid__i_column: 16,
		grid__list_breakpoint_id: ('sma', 'tab', 'des'),
		grid__map_breakpoint_data: (sma:(i_width:544), tab:(i_width:993), des:(i_width:10000)),


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : animation 

		///h :
		animation__b_embed: true,
		animation__s_duration__short: '0.4s',
		animation__s_duration__medium: '0.8s',
		animation__s_duration__long: '1.2s',
		animation__s_duration__xxl: '1.6s',
		animation__s_duration__1: #{(0.4 * 1)'s'},
		animation__s_duration__2: #{(0.4 * 2)'s'},
		animation__s_duration__3: #{(0.4 * 3)'s'},
		animation__s_duration__4: #{(0.4 * 4)'s'},
		animation__s_duration__5: #{(0.4 * 5)'s'},
		animation__s_duration__6: #{(0.4 * 6)'s'},

 
		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : layout
		
		///h :
		layout__b_embed: true,
		layout__s_unit__mini: '5px',
		layout__s_unit__sma: '10px',
		layout__s_unit__tab: '20px',
		layout__s_unit__des: '30px',
		layout__s_unit__xxl: '40px',
		layout__s_unit__xxxl: '80px',
		layout__s_unit__1: #{(10 * 1)'px'},
		layout__s_unit__2: #{(10 * 2)'px'},
		layout__s_unit__3: #{(10 * 3)'px'},
		layout__s_unit__4: #{(10 * 4)'px'},
		layout__s_unit__5: #{(10 * 6)'px'},
		layout__s_unit__6: #{(10 * 8)'px'},


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : ease
 
		///h :
		map_ease:
			(
			linear: linear,
			easeInQuad: cubic-bezier(0.550, 0.085, 0.680, 0.530),
			easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940),
			easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955),
			easeInCubic: cubic-bezier(0.550, 0.055, 0.675, 0.190),
			easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000),
			easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000),
			easeInQuart: cubic-bezier(0.895, 0.030, 0.685, 0.220),
			easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1.000),
			easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000),
			easeInQuint: cubic-bezier(0.755, 0.050, 0.855, 0.060),
			easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000),
			easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000),
			easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715),
			easeInOutSine: cubic-bezier(0.445, 0.050, 0.550, 0.950),
			easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000),
			easeInExpo: cubic-bezier(0.950, 0.050, 0.795, 0.035),
			easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000),
			easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000),
			easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335),
			easeOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860),
			easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860),
			easeInBack: cubic-bezier(0.600, -0.280, 0.735, 0.045),
			easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275),
			easeInOutBack: cubic-bezier(0.680, -0.550, 0.265, 1.550)
			),

		) !global !default;
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////
///h : init data
/**
 * init data
 * used to init all data later on used in the class context like
 * configuration arrays etc.
 *
 * @access public
 * @param
 * @return vault
 */
@mixin dc7__m_initContent()
	{
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////
///h : init data
/**
 * init data
 * used to init all data later on used in the class context like
 * configuration arrays etc.
 *
 * @access public
 * @param
 * @return vault
 */
@mixin dc7__m_initConfig($map_config)
	{
	///h : update config
	$dc7__map_data: map-merge($dc7__map_data, $map_config) !global;
	}

	

///h : transform - init system
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 */
@mixin dc7__m_embedStyles()
	{
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : reset

	///h : reset
	@if(_map($dc7__map_data, 'reset__b_embed') == true)
		{
		///h :
		@include dc7__reset__m_embedStyles();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : grid

	///h : grid
	@if(_map($dc7__map_data, 'grid__b_embed') == true)
		{
		///h :
		@include dc7__grid__m_embedStyles();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : type

	///h :
	@if(_map($dc7__map_data, 'type__b_embed') == true)
		{
		///h : default
/*		@include dc7__type__m_font($s_preset_id:'default', $s_selector__base:'.content')
			{
			}*/
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : layout

	///h : 
	@if(_map($dc7__map_data, 'layout__b_embed') == true)
		{
		///h :
		@include dc7__layout__m_embedStyles();
		}
 

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : layout
 
	///h :
	@if(_map($dc7__map_data, 'animation__b_embed') == true)
		{
		///h :
		@include dc7__animation__m_embedStyles();
		}


	///h : animation - no transition
//	.dc7__animation__notransition{@include dc7__animation__m_notransition();};

/*	///h : grid
	.dc7__grid__item{@include dc7__grid__m_item();};
	.dc7__grid__ratiosizer{@include dc7__grid__m_ratiosizer();};
	.dc7__grid__col__12-12{border:2px solid red !important; width:50% !important;}; 
 
	///h : layout 
	.dc7__layout__overflow__viewport{@include dc7__layout__m_overflow($s_preset_id:'viewport');};
	.dc7__layout__overflow__content{@include dc7__layout__m_overflow($s_preset_id:'content');};
	.dc7__layout__opacity__0{@include dc7__layout__m_opacity($num_opacity:0);};
	.dc7__layout__opacity__1{@include dc7__layout__m_opacity($num_opacity:1);};

	///h : fx
	.dc7__fx__fade__in__0-8{@include dc7__animation__m_animation($s_id:'dc7__animation__kf_fade__in', $s_duration:'0.8s', $s_repeat:'1', $s_ease:'Expo.easeOut');};
	}*/
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : methods - short function
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@function _boolean($s_data)
	{
	///h :
	@return(to-boolean($s_data));
	}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@function _num($s_data, $b_stripunits:false)
	{
	///h :
	$num_data: null;	///h 

	///h :
	$num_data: -1;
	@if($s_data != null)
		{
		///h :
		$num_data: dc7__sass__f_toNumber($s_data, $b_stripunits)
		}

	///h : 
	@return($num_data);
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@function _gdata($s_key:null, $o_value:null)
	{
	///h :
	$dc7__map_data: _map($dc7__map_data, $s_key, $o_value, $s_return__type:'map') !global;

	///h : 
	@return(map-get($dc7__map_data, $s_key));
	}	
 

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@function _map($map_data:null, $s_key:null, $o_value:null, $s_return__type:'value')
	{
	///h :
	@return(dc7__sass__f_map($map_data:$map_data, $s_key:$s_key, $o_value:$o_value, $s_return__type:$s_return__type));
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : methods - reset
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : reset - reset styles
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__reset__m_embedStyles($s_selector__base:'')
	{
	///h : default reset of all elements
	html,
	body,
	div,
	span,
	object,
	iframe,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	blockquote,
	pre,
	abbr,
	address,
	cite,
	code,
	del,
	dfn,
	em,
	img,
	ins,
	kbd,
	q,
	samp,
	small,
	strong,
	sub,
	sup,
	var,
	b,
	i,
	dl,
	dt,
	dd,
	ol,
	ul,
	li,
	fieldset,
	form,
	label,
	legend,
	table,
	caption,
	tbody,
	tfoot,
	thead,
	tr,
	th,
	td,
	article,
	aside,
	figure,
	footer,
	header,
	menu,
	nav,
	section,
	time,
	mark,
	audio,
	video,
	details,
	summary
		{
		margin: 0px 0px 0px 0px;
		padding: 0px 0px 0px 0px;
		border: none;
		outline: none;
		background: transparent;
		font-size: 100%;
		font-weight: normal;
		white-space: normal;
		line-height: 1.25em;
		vertical-align: top;
		}

	///h : html5 display-role reset for older browsers 
	article,
	aside,
	details,
	figcaption,
	figure,
	footer,
	header,
	hgroup,
	menu,
	nav,
	section
		{
		display: block;
		}

	///h :
	html
		{
		font-size: 16px;
		}

	///h :
	html.__dc7__system__s_device__smartphone
		{
/*		overflow-x: hidden;*/
		}

	///h :
	body
		{
/*		overflow: hidden;
		overflow-y: scroll;*/
		}

	///h : list style
	ol,
	ul
		{
		list-style: none;
		}


	html{
		box-sizing: border-box;
	}
	*,*:before,*:after{
		box-sizing: inherit;
	}


	///h : image fix
	img
		{
		-ms-interpolation-mode: bicubic;
		border: none;
		height: auto;
		line-height: 100%;
		text-decoration: none;
		background: none;
		outline: 1px solid transparent;
		vertical-align: top;

		///h :
		@include dc7__sass__m_prefixes($s_property_name:box-user-select, $s_property_value:none);

		///h :
		@include dc7__sass__m_prefixes($s_property_name:transform-style, $s_property_value:preserve-3d);
		}

	///h : typekit badge
	.typekit-badge
		{
		visibility: hidden !important;
		border: none;
		display: none !important;
		}

	///h : font smoothing fix for webkit
	html
		{
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-ms-text-size-adjust: none;
		-webkit-text-size-adjust: none;
		-ms-interpolation-mode: nearest-neighbor;
		text-rendering: optimizeLegibility;
		font-feature-settings: 'kern';
		-webkit-font-feature-settings: 'kern';
		-moz-font-feature-settings: 'kern';
		-moz-font-feature-settings: 'kern=1';
		}

	///h : blockquote
	blockquote,
	q
		{
		quotes: '“' '”';
		}

	///h :
	q:lang(de)
		{
		quotes: '„' '“';
		}

	///h :
	blockquote::before,
	blockquote::after,
	q::before,
	q::after
		{
		content: '';
		content: none;
		}

	///h : links
	a,
	a:link,
	a:visited,
	a:hover
		{
		font-size: 100%;
		background: transparent;
		text-decoration: none;
		color: inherit;
		cursor: pointer;
		margin: 0px 0px 0px 0px;
		padding: 0px 0px 0px 0xp;
		}

	///h : del
	del
		{
		text-decoration: line-through;
		}

	///h :
	pre
		{
		white-space: pre;
		white-space: pre-wrap;
		white-space: pre-line;
		word-wrap: break-word;
		}

	///h : hand cursor on clickable elements
	.clickable,
	input[type='button'],
	input[type='submit'],
	input[type='file'],
	input[type='checkbox'],
	button,
	a 
		{
		cursor: pointer;
		}

	///h : 
	button,
	input,
	select,
	textarea,
	optgroup,
	option
		{
		margin: 0px 0px 0px 0px;
		padding: 0px 0px 0px 0px;
		font-family: inherit;
		font-size: inherit;
		font-style: inherit;
		font-weight: inherit;
		box-shadow: none;
		}

	///h : inline text
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	b,
	br,
	i,
	small,
	span
		{
		///h :
		vertical-align: baseline;

		///h :
		& >*
			{
			font-size: inherit;
			line-height: inherit;
			font-family: inherit;
			//font-weight: inherit;
			}
		}

	///h :
	strong,
	b,
	p > strong,
	p > b
		{
		font-weight: bold;
		font-size: inherit;
		font-family: inherit;
		vertical-align: inherit;
		}

	///h :
	em,
	i,
	p > em,
	p > i
		{
		font-style: italic;
		font-size: inherit;
		font-family: inherit;
		vertical-align: inherit;
		}

	///h :
	br 
		{
		display: inline;
		}

	///h : focus styles
	:focus
		{
		outline: 0;
		}

	///h : table
	table
		{
		border-collapse: separate;
		border-spacing: 0;
		}

	///h :
	caption,
	th,
	td
		{
		text-align: left;
		font-weight: normal;
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : methods - math
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : math - power
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@function dc7__math__f_ruleOfTree($num_data__a__1, $num_data__a__2, $num_data__b__1)
	{
	///h :
	@return((_num($num_data__a__2) / _num($num_data__a__1)) * _num($num_data__b__1));
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : math - power
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@function dc7__math__f_power($num_base, $num_power)
	{
	///h :
	$num_result: null;		///h :
	$i_loop: null;			///h :

	///h :
	$num_result: 1;
	@if $num_power > 0
		{
		///h :
		@for $i_loop from 1 through $num_power
			{
			///h :
			$num_result: ($num_result * $num_base);
			}
		}
	@else if $num_power < 0
		{
		///h :
		@for $i_loop from 1 through $num_power
			{
			///h :
			$num_result: ($num_result / $num_base);
			}
		}

	///h :
	@return($num_result);
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : methods - sass
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@function dc7__sass__f_urlEncode($s_data:null)
	{
	///h : 
	$s_data: to-string($s_data);
	$s_data: dc7__sass__f_replace($s_data:$s_data, $s_search:'<', $s_replace:'%3C');
	$s_data: dc7__sass__f_replace($s_data:$s_data, $s_search:'>', $s_replace:'%3E');
	$s_data: dc7__sass__f_replace($s_data:$s_data, $s_search:'/', $s_replace:'%2F');
	$s_data: dc7__sass__f_replace($s_data:$s_data, $s_search:'=', $s_replace:'%3D');
	$s_data: dc7__sass__f_replace($s_data:$s_data, $s_search:'"', $s_replace:"'");
	$s_data: dc7__sass__f_replace($s_data:$s_data, $s_search:',', $s_replace:'%2C');
	$s_data: dc7__sass__f_replace($s_data:$s_data, $s_search:'&', $s_replace:'%26');
	$s_data: dc7__sass__f_replace($s_data:$s_data, $s_search:':', $s_replace:'%3A');
	$s_data: dc7__sass__f_replace($s_data:$s_data, $s_search:'#', $s_replace:'%23');

	///h :
	@return($s_data);
	}
	

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */ 
@function dc7__sass__f_explode($s_data, $s_glue:',', $b_debug:false, $b_trim:true)
	{
	///h : 
	$list_data: null;	///h :
	$i_data: null;		///h :
	$i_loop: null;		///h :
	$i_index: null;		///h :
	$i_length: null;	///h :

	///h :
	$s_data: if(($s_data == null), '', $s_data);
	$s_data: str-trim(to-string($s_data)); 

	///h :
	$list_data: ();
	$i_data: str-length($s_data);
	$i_index: 0;

	///h :
	$s_data__current: ''; 
	@while $i_index <= $i_data
		{
		///h :
		$s_data__rest: str-slice($s_data, $i_index);

		///h :
		@if(str-index($s_data__rest, $s_glue) == 1)
			{ 
			///h :
			$s_data__current: str-trim($s_data__current);
			$s_data__current: str-replace($s_data__current, $s_glue, '');

			///h :
			$list_data: append($list_data, $s_data__current);
			$s_data__current: ''; 

			///h :
			$i_length: length($s_glue);
			}
		///h :
		@else
			{
			///h :
			$i_length: 1;
			}
 
		///h :
		$s_data__current: ($s_data__current + '' + str-slice($s_data, $i_index, ($i_index + $i_length - 1)));
		$i_index: ($i_index + $i_length);
		}
 
	///h :
	$s_data__current: str-trim($s_data__current);
	$s_data__current: dc7__sass__f_replace($s_data:$s_data__current, $s_search:$s_glue, $s_replace:'');
	$s_data__current: if(($s_data__current == ''), $s_data, $s_data__current);
	$list_data: append($list_data, $s_data__current);

	///h :
	@if(length($list_data) == 0)
		{
		///h :
		$list_data: append($list_data, $s_data);
		}
		
	


	///h :
	@if(length($list_data) == 1)
		{
		///h :
		@if((nth($list_data, 1) == '') or (nth($list_data, 1) == null))
			{
			///h :
			$list_data: ();
			}
		}

	///h :
	@return($list_data);
	} 
 

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *  
 * @access public container
 * @param
 * @return vault
 *
 */
@function dc7__sass__f_implode($list_data:null, $s_glue:', ')
	{
	///h :
	$s_data: null;			///h :
	$i_elements: null;		///h :
	$i_loop: null;			///h :

	///h :
	$i_elements: length($list_data);
	@if($i_elements > 0)
		{
		///h :
		@for $i_loop from 1 through $i_elements
			{
			///h :
			$s_data__part: nth($list_data, $i_loop);
			$s_data: (if(($i_loop != $i_elements), ('#{$s_data}#{$s_data__part}#{$s_glue}'), ('#{$s_data}#{$s_data__part}')));
			}
		}
 
	///h :
	@return($s_data);
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@function dc7__sass__f_map($map_data:$dc7__map_data, $s_key:null, $o_value:null, $s_return__type:'value')
	{
	///h :
	$o_data: null;		///h :
	$s_mode: null;		///h :

	///h :	
	$s_mode: if(($o_value == null), 'get', 'set');
	$s_return__type: if(($s_key == null), 'map', $s_return__type);

	///h : set
	@if($s_mode == 'set') 
		{
		///h :
		$map_current: ($s_key: $o_value);
		$map_data: map-merge($map_data, $map_current);
		}

	///h :
//	$o_data: if(($s_mode == 'get'), map-get($map_data, $s_key), $map_data);

	///h :
	@if($s_return__type == 'value')
		{
		///h :
		$o_data: map-get($map_data, $s_key);
		} 
	///h :
	@else
		{
		///h :
		$o_data: $map_data;
		}

	///h : 
	@return($o_data);
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@function dc7__sass__f_convertPXtoREM($s_size__px)
	{
	///h : calc
	$num_size__px: _num($s_size__px);
	$s_size: #{($num_size__px / 16px)}rem;

	///h : return
	@return($s_size);
	}
	

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@function dc7__sass__f_getStringCommand__id($s_data)
	{
	$i_index: str-index($s_data, '__');
	$s_data: str-slice($s_data, 0, ($i_index - 1));

	///h :
	@return($s_data);
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@function dc7__sass__f_toNumber($s_data, $b_stripunits:false)
	{
	///h :
	$num_data: to-number($s_data);

	///h :
	@if($b_stripunits == true)
		{
		$num_data: ($num_data / ($num_data * 0 + 1));
		}

	///h :
	@return($num_data);
	}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@function dc7__sass__f_getStringCommand__value($s_data)
	{
	///h :
	$i_index: (str-index($s_data, '__') + 2);
	$s_data: str-slice($s_data, $i_index);
	
	///h :
	$s_data: dc7__sass__f_replace($s_data:$s_data, $s_search:'*', $s_replace:' * ');
	$s_data: dc7__sass__f_replace($s_data:$s_data, $s_search:'+', $s_replace:' + ');
	$s_data: dc7__sass__f_replace($s_data:$s_data, $s_search:'-', $s_replace:' - ');
	$s_data: dc7__sass__f_replace($s_data:$s_data, $s_search:'/', $s_replace:' / ');

	///h :
	@return($s_data);
	}
 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@function dc7__sass__f_replace($s_data, $s_search, $s_replace:'')
	{
	///h :
	@if($s_data != null)
		{
		///h :
		$s_data: str-replace($s_data, $s_search, $s_replace);
		}

	///h :
	@return($s_data);
	}
	
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@function dc7__sass__f_getSelector($list_selector:null, $s_selector__base:null, $b_debug:false)
	{
	///h :
	$s_selector: null;			///h :
	$i_selector: null;			///h :
	$list_selector__manipulated: null;	///h :
 
	///h :
	@if(type-of($list_selector) == string)
		{
		$list_selector: dc7__sass__f_explode($s_data:$list_selector, $s_glue:',');
		}

	///h :
	$list_selector: (if(($list_selector == null), (''), $list_selector));

	///h :
	$i_selector: length($list_selector);
	$list_selector__manipulated: ();
  
	///h :
	@if($i_selector > 0)
		{
		///h :
		@for $i_loop from 1 through $i_selector
			{		
			///h :
			$s_selector__loop: nth($list_selector, $i_loop);

			//$s_selector: if(($s_selector__base == null), nth($list_selector, $i_loop), ($s_selector__base + ' ' + nth($list_selector, $i_loop)));
			@if($s_selector__base == null)
				{
				///h :
				$s_selector: nth($list_selector, $i_loop);
				} 
			///h : 
			@else
				{
				///h : 
				$s_selector: ($s_selector__base + ' ' + nth($list_selector, $i_loop));
				}
			$s_selector: dc7__sass__f_replace($s_data:$s_selector, $s_search:'  ', $s_replace:' ');

			///h :
			@if($s_selector != '')
				{
				///h :
				$list_selector__manipulated: append($list_selector__manipulated, $s_selector); 
				}
			}
		} 
 
	///h : only base selector
	@if($s_selector == null)
		{
		///h :
		@if($s_selector__base != null)
			{
			///h :
			$s_selector: $s_selector__base;
			}
		}

	///h :
	$s_selector: dc7__sass__f_implode($list_data:$list_selector__manipulated, $s_glue:', ');

	///h : double html
	$s_selector: dc7__sass__f_replace($s_data:$s_selector, $s_search:'html html', $s_replace:'html');

	///h : return
	@return($s_selector); 
	}
 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@function dc7__sass__f_stripUnits($num_data)
	{
	///h :
	$num_data: _num($num_data);
	$num_data: ($num_data / ($num_data * 0 + 1));
 
	///h :
	@return($num_data);
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@mixin dc7__sass__m_keyframes($s_id)
	{
	///h : 
	@-webkit-keyframes #{$s_id}
		{
		///h : 
		@content;
		}
	///h : 
	@-moz-keyframes #{$s_id}
		{
		///h : 
		@content;
		}
	///h : 
	@-o-keyframes #{$s_id}
		{
		///h : 
		@content;
		}
	///h : 
	@keyframes #{$s_id}
		{
		///h : 
		@content;
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 * 
 */
@mixin dc7__sass__m_debug($s_debug:'')
	{
	///h :
	$s_debug: to-string($s_debug);

	///h :
	@debug($s_debug);

	///h :
	content: ('debug >>' + $s_debug + '<<');
	}

	
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : sass - condition
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault 
 *  
 */ 
@mixin dc7__sass__m_style($b_breakpoints:true, $s_style_selector:null)
	{
	///h :
	$list_breakpoint_id: _map($dc7__map_data, 'grid__list_breakpoint_id');
	$list_breakpoint_id: append($list_breakpoint_id, 'def');
	$list_breakpoint_id: append($list_breakpoint_id, '');
	$s_style_selector__firstchar: char-at($s_style_selector, 1);
	$s_style_selector__name: str-slice($s_style_selector, 2);

	///h : no breakpoints
	@if($b_breakpoints == false)
		{
		$list_breakpoint_id: ('');
		}

	///h : insert all classes with relevant prefixes for
	$i_breakpoint_id: length($list_breakpoint_id);
	$list_selector: ();
	@for $i_loop from 1 through $i_breakpoint_id
		{  
		///h :
		$s_breakpoint_id: nth($list_breakpoint_id, $i_loop);  
		$s_selector__base: if(($s_breakpoint_id == ''), '#{$s_style_selector__firstchar}#{$s_style_selector__name}', 'html.dc7__grid__s_breakpoint_id__#{$s_breakpoint_id} #{$s_style_selector__firstchar}#{$s_style_selector__name}__#{$s_breakpoint_id}');
		$list_selector: append($list_selector, $s_selector__base);
		}
 
	///h : 
	$s_selector__base: dc7__sass__f_implode($list_data:$list_selector, $s_glue:', ');

	///h :
	#{$s_selector__base}
		{
		@content;
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : sass - condition
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault 
 *  
 */ 
@mixin dc7__sass__m_selector($s_breakpoint_id:'def', $list_selector:null, $s_selector__base:null, $i_mediaquery__from:0, $i_mediaquery__to:50000, $s_mediaquery__type:'screen', $s_mediaquery:null, $b_debug:false, $b_root:false)
	{
	///h : 
	$s_selector__breakpoint: null;		///h :
	$s_selector__current: null;		///h :
	$s_selector: null;			///h :
	
	///h : list selector parsing
	$s_selector__current: if(($b_root == true), null, '#{&}');
	$list_selector: if(($list_selector == null), $s_selector__current, $list_selector);
	$s_selector: dc7__sass__f_getSelector($list_selector:$list_selector, $s_selector__base:$s_selector__base, $b_debug:$b_debug); 
	
	///h : breakpoint selector parsing
	@if($s_breakpoint_id != 'def')
		{
		///h : 
		$s_selector__breakpoint: ('html.dc7__grid__s_breakpoint_id__#{$s_breakpoint_id}');
		}
	///h :
	@else
		{
		///h :
		$s_selector__breakpoint: ('html');
		}

	///h : remove html from current selector
	$s_selector: dc7__sass__f_replace($s_data:$s_selector, $s_search:'html ', $s_replace:'');
	$s_selector: dc7__sass__f_getSelector($list_selector:$s_selector, $s_selector__base:$s_selector__breakpoint, $b_debug:false);

	///h : media query selector parsing
	@if(($i_mediaquery__from != 0) or ($i_mediaquery__to != 50000))
		{
		///h :
		$s_mediaquery: ('#{$s_mediaquery__type} and (min-width: #{$i_mediaquery__from}px) and (max-width: #{$i_mediaquery__to}px)');
		}

	///h :
	@if($s_mediaquery != null)
		{
		///h :
		@media #{$s_mediaquery}
			{
			///h :
			@at-root #{$s_selector}
				{
				///h :
				@content;
				}
			}
		} 
	///h :
	@else
		{
		///h :
		@at-root #{$s_selector}
			{
			///h :
			@content; 
			}
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : sass - prefixes
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__sass__m_prefixes($s_property_name:null, $s_property_value:null, $b_important:false)
	{
	///h : add important to values
	$s_important: ''; 
	@if($b_important == true)
		{
		$s_important: '!important';
		}

	///h : prefixed property
	-webkit-#{$s_property_name}: #{$s_property_value}#{$s_important};
	-moz-#{$s_property_name}: #{$s_property_value}#{$s_important};
	-ms-#{$s_property_name}: #{$s_property_value}#{$s_important};
	-o-#{$s_property_name}: #{$s_property_value}#{$s_important};
	#{$s_property_name}: #{$s_property_value}#{$s_important};
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : methods - grid
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : init content
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */ 
@mixin dc7__grid__m_embedStyles()
	{
	///h :
	$i_elements: null;		///h :
	$i_loop: null;			///h :
	$i_elements: null;		///h :

	///h :
	@include dc7__sass__m_style($b_breakpoints:false, $s_style_selector:'div[class*="dc7__grid__col__"]'){@include dc7__grid__m_item();};

	///h : grid - 2 col
	$i_elements: 2;
	@for $i_loop from 1 through $i_elements
		{ 
		@include dc7__sass__m_style($b_breakpoints:true, $s_style_selector:'.dc7__grid__col__#{$i_loop}-#{$i_elements}'){@include dc7__grid__m_item($b_style:true, $size__s_width:'column__#{$i_loop}/#{$i_elements}');};
		}

	///h : grid - 4 col 
	$i_elements: 4;
	@for $i_loop from 1 through $i_elements
		{ 
		@include dc7__sass__m_style($b_breakpoints:true, $s_style_selector:'.dc7__grid__col__#{$i_loop}-#{$i_elements}'){@include dc7__grid__m_item($b_style:true, $size__s_width:'column__#{$i_loop}/#{$i_elements}');};
		}
		
	///h : grid - 8 col
	$i_elements: 8;
	@for $i_loop from 1 through $i_elements
		{ 
		@include dc7__sass__m_style($b_breakpoints:true, $s_style_selector:'.dc7__grid__col__#{$i_loop}-#{$i_elements}'){@include dc7__grid__m_item($b_style:true, $size__s_width:'column__#{$i_loop}/#{$i_elements}');};
		}

	///h : grid - system col
	$i_elements: _map($dc7__map_data ,'grid__i_column');
	@for $i_loop from 1 through $i_elements
		{ 
		@include dc7__sass__m_style($b_breakpoints:true, $s_style_selector:'.dc7__grid__col__#{$i_loop}-#{$i_elements}'){@include dc7__grid__m_item($b_style:true, $size__s_width:'column__#{$i_loop}/#{$i_elements}');};
		}
	}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : init content
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__grid__m_html($s_align:'center')
	{
	text-align: #{$s_align};
//	overflow-x: hidden;
	}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : init content
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__grid__m_body($s_align:'center')
	{
	text-align: #{$s_align};
	}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__grid__m_page($padding__num_innerfactor:0, $padding__num_outerfactor:0, $padding__s_unitsize:'20px', $offset__num_horizontaloffset:0, $offset__num_verticaloffset:0, $offset__s_unitsize:'20px')
	{
	///h : dcscss - grid - container
	@include dc7__grid__m_container($padding__num_innerfactor:$padding__num_innerfactor, $padding__num_outerfactor:$padding__num_outerfactor, $padding__s_unitsize:$padding__s_unitsize);
 
	///h :
	width: 100%;
	min-height: 100vh;
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : init content
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__grid__m_content($s_preset_id:null, $size__s_width__max:null, $padding__num_innerfactor:0, $padding__num_outerfactor:0, $padding__s_unitsize:'20px', $offset__num_horizontaloffset:0, $offset__num_verticaloffset:0, $offset__s_unitsize:'20px')
	{
	///h : preset - content
	@if($s_preset_id == 'content')
		{
		$size__s_width__max: '590px';
		}
	///h : preset - content wp
	@else if($s_preset_id == 'wp')
		{
		$size__s_width__max: '1080px';
		}
	///h : preset - content wp
	@else if($s_preset_id == 'chewing')
		{
		$size__s_width__max: '1510px';
		}
		
	///h : dcscss - grid - container
	@include dc7__grid__m_row($padding__num_innerfactor:$padding__num_innerfactor, $padding__num_outerfactor:$padding__num_outerfactor, $padding__s_unitsize:$padding__s_unitsize, $offset__num_horizontaloffset:$offset__num_horizontaloffset, $offset__num_verticaloffset:$offset__num_verticaloffset, $offset__s_unitsize:$offset__s_unitsize);

	///h :
	max-width: #{$size__s_width__max};
	min-height: 100vh;
	text-align: center;
	}
	
	
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__grid__m_ratiocontainer($s_ratio:null, $b_childfill:true)
	{
	///h :
	$perc_data: null;		///h : 
		
	///h :
	position: relative;
	width: 100%;

	///h :  
	@if($s_ratio != null)
		{
		///h : 
		&:before
			{
			display: block;
			content: '';
			width: 100%;
			$perc_data: (100% / #{$s_ratio});
			padding-top: $perc_data;
			}
		}

	///h :  
	& > *
		{
		position: absolute;
		top: 0px;
		left: 0px;
		}
	
	///h :
	@if($b_childfill == true)
		{
		///h :  
		& > *,
		& > div,
		& > img
			{
			width: 100%;
			height: 100%;
			}
		}
	}
	
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__grid__m_ratiosizer($s_ratio:null, $s_size:'width')
	{
	///h :
	$perc_data: null;		///h :

	///h :
	position: relative;
	background-color: transparent;
	z-index: -1;

	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'noattract');

	///h :  
	@if($s_size == 'width')
		{
		width: 100%;
		@if($s_ratio != null)
			{
			$perc_data: (100% / #{$s_ratio});
			padding-top: #{$perc_data};
			}
		}
		
	///h :  
	@if($s_size == 'height')
		{
		background: red !important;
		width: 200px !important;
		height: 200px !important;
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__grid__m_row($padding__num_innerfactor:0, $padding__num_outerfactor:0, $padding__s_unitsize:'20px', $offset__num_horizontaloffset:0, $offset__num_verticaloffset:0, $offset__s_unitsize:'20px')
	{
	///h :
	width: 100%;

	///h : dcscss - grid - container
	@include dc7__grid__m_container($padding__num_innerfactor:$padding__num_innerfactor, $padding__num_outerfactor:$padding__num_outerfactor, $padding__s_unitsize:$padding__s_unitsize);
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@mixin dc7__grid__m_container($padding__num_innerfactor:0, $padding__num_outerfactor:0, $padding__s_unitsize:'20px', $offset__num_horizontaloffset:0, $offset__num_verticaloffset:0, $offset__s_unitsize:'20px')
	{ 
	///h : dcscss - grid - item
	@include dc7__grid__m_item($padding__num_innerfactor:$padding__num_innerfactor, $padding__num_outerfactor:$padding__num_outerfactor, $padding__s_unitsize:$padding__s_unitsize);

	///h : offset
	//@include dc7__layout__m_offset($num_horizontaloffset:#{$offset__num_horizontaloffset}, $num_verticaloffset:#{$offset__num_verticaloffset}, $s_unitsize:#{$offset__s_unitsize});

	///h : remove space between items
	font-size: 0px;
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : grid - row
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public container
 * @param
 * @return vault
 *
 */
@mixin dc7__grid__m_item($padding__s_inner:null, $padding__num_innerfactor:0, $padding__num_outerfactor:0, $padding__s_unitsize:'20px', $size__s_width:null, $b_style:false)
	{
	///h :
	@if($b_style == false)
		{
		///h : positioning
		position: relative;
		display: inline-block;
		box-sizing: border-box;
		background-clip: padding-box;
		}

	///h : padding
	@include dc7__layout__m_padding($s_inner:$padding__s_inner, $num_innerfactor:$padding__num_innerfactor, $num_outerfactor:$padding__num_outerfactor, $s_unitsize:$padding__s_unitsize);

	///h : size
	@if($size__s_width != null)
		{
		@include dc7__layout__m_size($s_width:$size__s_width);
		}

	///h : debug
	@if(_map($dc7__map_data, 'grid__b_debug') == true)
		{
	//	background-color: rgba(red, 0.5);
	//	border-color: rgab(red, 0.75);
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - align
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__layout__m_backgroundSVG($s_svg:null, $color_svg:null)
	{
	///h :
	@if($color_svg != null)
		{ 
		///h :
		$s_svg: dc7__sass__f_replace($s_data:$s_svg, $s_search:'{$s_color}', $s_replace:$color_svg);
		}

	///h :
	$s_svg: dc7__sass__f_urlEncode($s_svg);
	

	///h :
	background: url('data:image/svg+xml,#{$s_svg}');
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - align
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__layout__m_embedStyles()
	{
	///h :
	@include dc7__sass__m_style($b_breakpoints:true, $s_style_selector:'.dc7__layout__visible__true'){@include dc7__layout__m_visible($b_visible:true);};
	@include dc7__sass__m_style($b_breakpoints:true, $s_style_selector:'.dc7__layout__visible__false'){@include dc7__layout__m_visible($b_visible:false);};
	@include dc7__sass__m_style($b_breakpoints:true, $s_style_selector:'.dc7__layout__align__left'){@include dc7__layout__m_align($s_horizontal:'left');};
	@include dc7__sass__m_style($b_breakpoints:true, $s_style_selector:'.dc7__layout__align__center'){@include dc7__layout__m_align($s_horizontal:'center');};
	@include dc7__sass__m_style($b_breakpoints:true, $s_style_selector:'.dc7__layout__align__right'){@include dc7__layout__m_align($s_horizontal:'right');};
	
	}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - align
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__layout__m_visible($b_visible:false, $s_display:'inline-block')
	{
	///h : properties
	$s_display: null;		///h :

	///h : data
	$s_display: if(($b_visible == false), 'none', $s_display);

	///h :
	display: #{$s_display};
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - align
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__layout__m_childalign($s_align:'left')
	{
	& > *
		{
		text-align: #{$s_align};
		}
	}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - align
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__layout__m_overflow($s_preset_id:null)
	{
	///h :
	@if($s_preset_id == 'viewport')
		{
		max-height: 100vh;
		max-width: 100vw;
/*		overflow-x: hidden;
		overflow-y: hidden;*/
		}
	///h :
	@else if($s_preset_id == 'content')
		{
		max-height: none;
		max-width: 100vw;
	//	overflow-x: visible;
	//	overflow-y: visible;
		}
	}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - align
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__layout__m_background($s_background_color)
	{
	///h :
	&:after
		{
		///h :
		background-color: #{$s_background_color};
		position: absolute;
		height: 100%;
		content: '';
		z-index: -1;
		top: 0px;

		///h : 
		width: 100vw; 
		left: calc((100% - 100vw) * 0.5);
		}
	}
	
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - align
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__layout__m_offset($num_horizontaloffset:0, $num_verticaloffset:0, $s_unitsize:'20px')
	{
	///h : horizontal offset
	@if($num_horizontaloffset != 0)
		{
		margin-left: calc(-#{$s_unitsize} * #{$num_horizontaloffset});
		width: calc(100% + #{$s_unitsize} * #{_num($num_horizontaloffset) * 2});	
		} 
	///h : vertical offset
	@if($num_verticaloffset != 0)
		{
		margin-top: calc(-#{$s_unitsize} * #{$num_verticaloffset});
		height: calc(100% + #{$s_unitsize} * #{_num($num_verticaloffset) * 2});
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - align
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__layout__m_flexbox($s_horizontal:null, $s_vertical:null)
	{
	@if(($s_horizontal != null) or ($s_vertical != null))
		{
		///h :
/*		display: -webkit-inline-flex;
		display: -ms-inline-flexbox;
		display: -moz-box;
		display: inline-flex;
		flex-wrap: wrap;*/
		display: inline-flex;
		}

	///h :
	@if($s_horizontal != null)
		{
		///h : right string
		@if($s_horizontal == 'left')
			{
			$s_horizontal: 'flex-start';
			}
		///h : right string
		@if($s_horizontal == 'right')
			{
			$s_horizontal: 'flex-end';
			}

		///h :
		@include dc7__sass__m_prefixes($s_property_name:'justify-content', $s_property_value:$s_horizontal, $b_important:false);
		}

	///h :
	@if($s_vertical != null)
		{
		///h : right string
		@if($s_vertical == 'middle')
			{
			$s_vertical: 'center';
			}
		///h : right string
		@if($s_vertical == 'top')
			{
			$s_vertical: 'flex-start';
			}
		///h : right string
		@if($s_vertical == 'bottom')
			{
			$s_vertical: 'flex-end';
			}

		///h :
		@include dc7__sass__m_prefixes($s_property_name:'align-items', $s_property_value:$s_vertical, $b_important:false);
		}
	}
	
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - align
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__layout__m_align($s_horizontal:'left', $s_vertical:null)
	{
	///h :
	}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - align
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__layout__m_transformalign($s_horizontal:null, $s_vertical:null, $b_blur:false, $s_position:absolute, $b_important:false)
	{
	///h : data
	$s_position_vertical: 0px;
	$s_position_horizontal: 0px;

	///h : browser rendering
	position: $s_position;

	///h : horizontal align
	@if(($s_horizontal != null) and ($s_vertical == null))
		{
		left: 50% !important;
		
		///h :
		@include dc7__sass__m_prefixes($s_property_name:transform, $s_property_value:translate(-50%, 0px), $b_important:$b_important);
		}
	///h : vertical align
	@else if(($s_horizontal == null) and ($s_vertical != null))
		{
		top: 50% !important;
		
		///h :
		@include dc7__sass__m_prefixes($s_property_name:transform, $s_property_value:translate(0px, -50%), $b_important:$b_important);
		}
	///h : vertical and horziontal align
	@else if(($s_horizontal != null) and ($s_vertical != null))
		{
		left: 50% !important;
		top: 50% !important;
		
		///h :
		@include dc7__sass__m_prefixes($s_property_name:transform, $s_property_value:translate(-50%, -50%), $b_important:$b_important);
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - padding
/**
 * aspect ratio
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 */
@mixin dc7__layout__m_selection($s_color:null, $s_background_color:null)
	{
	///h :
	::-moz-selection
		{
		color: #{$s_color};
		background: #{$s_background_color};
		}

	///h :
	::selection
		{
		color: #{$s_color};
		background: #{$s_background_color};
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - padding
/**
 * aspect ratio
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 */
@mixin dc7__layout__m_opacity($num_opacity:null)
	{
	///h :
	$num_opacity_ie: ($num_opacity * 100);

	///h : 
	opacity: #{$num_opacity};
	filter: alpha(opacity=#{$num_opacity_ie});
	}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - padding
/**
 * aspect ratio
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 */
@mixin dc7__layout__m_border($color_border:null, $s_width:null, $s_radius:null, $s_spacing:null, $s_style:null)
	{
	///h :
	border-color: #{$color_border};
	border-width: #{$s_width};
	border-radius: #{$s_radius};
	border-spacing: #{$s_spacing};
	border-style: #{$s_style};
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - padding
/**
 * aspect ratio
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 */
@mixin dc7__layout__m_padding($s_inner:null, $num_innerfactor:0, $num_outerfactor:0, $s_unitsize:'20px')
	{
	///h : padding - inner
	@if($num_innerfactor != 0)
		{
		$s_inner: #{'calc(#{$s_unitsize} * #{$num_innerfactor})' 'calc(#{$s_unitsize} * #{$num_innerfactor})' 'calc(#{$s_unitsize} * #{$num_innerfactor})' 'calc(#{$s_unitsize} * #{$num_innerfactor})'};
		}
	@if($s_inner != null)
		{
		padding: #{$s_inner};
		}
	
	///h : padding - inner
	@if($num_outerfactor != 0)
		{
		border-width: #{'calc(#{$s_unitsize} * #{$num_outerfactor})'};
		border-color: rgba(255, 255, 255, 0);
		border-style: solid;
		}
	}
	
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - padding
/**
 * aspect ratio
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 */
@mixin dc7__layout__m_margin($s_margin:null, $s_margin__left:null, $s_margin__top:null, $s_margin__right:null, $s_margin__bottom:null, $num_factor:1, $s_unit:'px')
	{
	///h :
	@if($s_margin != null)
		{
		$s_margin__left: $s_margin;
		$s_margin__top: $s_margin;
		$s_margin__right: $s_margin;
		$s_margin__bottom: $s_margin;
		}

	///h :
	@if($s_margin__top != null)
		{
		margin-top: #{(_num($s_margin__top) * $num_factor)};
		}
	///h :
	@if($s_margin__right != null)
		{
		margin-right: #{(_num($s_margin__right) * $num_factor)};
		}
	///h :
	@if($s_margin__bottom != null)
		{
		margin-bottom: #{(_num($s_margin__bottom) * $num_factor)};
		}
	///h :
	@if($s_margin__left != null)
		{
		margin-left: #{(_num($s_margin__left) * $num_factor)};
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : layout - size
/**
 * aspect ratio
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 */
@mixin dc7__layout__m_size($s_width:null, $s_height:null)
	{
	///h :
	$perc_data: null;	///h :
	$list_data: null;	///h : 
		
	///h :
	@include dc7__grid__m_item();

	///h :
	@if($s_width != null)
		{
		///h :
		$s_command__id: dc7__sass__f_getStringCommand__id($s_width);
		$s_command__value: dc7__sass__f_getStringCommand__value($s_width);

		///h : width is transmitted by col
		@if($s_command__id == 'column')
			{
			///h : 
			$list_data: dc7__sass__f_explode($s_data:$s_command__value, $s_glue:'/', $b_debug:false, $b_trim:true);
			$perc_data: (to-number(str-trim(nth($list_data, 1)))/to-number(str-trim(nth($list_data, 2))));
			$perc_data: percentage($perc_data);
			width: #{$perc_data}; 
			} 
		///h : width is transmitted by col
		@else if($s_command__id == 'static')
			{
			width: #{$s_width};
			}
		}

	///h :
	@if($s_height != null)
		{
		///h :
		$s_command__id: dc7__sass__f_getStringCommand__id($s_height);
		$s_command__value: dc7__sass__f_getStringCommand__value($s_height);

		///h : check if width is transmitted by col
		@if($s_command__id == 'relative')
			{
			//$s_height: calc(100% * #{$s_command__value});

			///h :
			&:before
				{
				///h :
				margin-top: 100%;
				content: '';
				display: block;
				}
				
			///h : 
			& > *:first-child
				{
				///h :
				@include dc7__grid__m_item();
				margin-top: -100%;
				}
			}
		///h : height is transmitted by viewport width
		@else if($s_command__id == 'vw')
			{
			$s_height: calc(100vw * #{$s_command__value});
			}
		///h : height is transmitted by col
		@else if($s_command__id == 'static')
			{
			$s_height: #{$s_command__value};
			}

		///h :
		min-height: #{$s_height};
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : methods - type
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : breakpoints - init system
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__type__m_embedStyles($s_selector__base:'')
	{
/*	///h : fonts
	@include dc7__type__m_font($s_preset_id:'default', $s_selector__base:$s_selector__base);
	@include dc7__type__m_font($s_preset_id:'p1', $s_selector__base:$s_selector__base);
	@include dc7__type__m_font($s_preset_id:'p2', $s_selector__base:$s_selector__base);
	@include dc7__type__m_font($s_preset_id:'p3', $s_selector__base:$s_selector__base);
	@include dc7__type__m_font($s_preset_id:'h1', $s_selector__base:$s_selector__base);
	@include dc7__type__m_font($s_preset_id:'h2', $s_selector__base:$s_selector__base);
	@include dc7__type__m_font($s_preset_id:'h3', $s_selector__base:$s_selector__base);
	@include dc7__type__m_font($s_preset_id:'h4', $s_selector__base:$s_selector__base);
	@include dc7__type__m_font($s_preset_id:'h5', $s_selector__base:$s_selector__base);
	@include dc7__type__m_font($s_preset_id:'h6', $s_selector__base:$s_selector__base);

	///h :
	#{$s_selector__base} .fa
		{
		font-size: #{_gdata('type__p1__s_fontsize')};
		}*/
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : breakpoints - init system
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__type__m_fontlayout($s_family:'Arial', $s_size:'16px', $s_color:_gdata('system__color_black'), $s_lineheight:'1.25em', $s_weight:'normal', $s_letterspacing:'0px', $s_style:'normal', $s_transform:'none', $map_breakpoints:(sma:0.4, tab:0.7, des:1.0))
	{ 
	///h : 
	$s_selector__current: null;		///h : 

	///h :
	$s_selector__current: #{&};

	///h : 
	font-family: #{$s_family};
	font-size: #{$s_size};
	color: #{$s_color};
	line-height: #{$s_lineheight};
	font-weight: #{$s_weight};
	letter-spacing: #{$s_letterspacing};
	font-style: #{$s_style};
	text-transform: #{$s_transform};
 
	///h :
	@if($map_breakpoints != null)
		{
		///h :
		@each $s_key, $o_value in $map_breakpoints
			{
			///h :
			@include dc7__sass__m_selector($s_breakpoint_id:$s_key, $b_debug:true)
				{
				///h :
				@if($s_key != 'des')
					{
					///h : 
					$num_breakpoint: _map($map_breakpoints, $s_key);		
					$num_size: dc7__sass__f_stripUnits($s_size);
					$num_factor: (1 / dc7__math__f_ruleOfTree($num_size, 70, 1));
					
					$num_resize: (_num($num_breakpoint) * _num($num_size));
					$num_difference: (_num($s_size) - $num_resize);
    
					///h : 
					$num_resize: (_num($s_size) - ($num_difference * $num_factor));
					$num_resize: if(($num_resize < 9), 9, $num_resize);
					$num_lineheight: dc7__math__f_ruleOfTree($num_size, dc7__sass__f_stripUnits(_num($num_resize)), dc7__sass__f_stripUnits(_num($s_lineheight)));
					$num_lineheight: if(($num_lineheight < 1.0), 1.0, $num_lineheight);
					
					///h : 
					font-size: #{$num_resize};
					line-height: #{$num_lineheight};
					}
				}
			}
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : breakpoints - init system
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 * 
 */
@mixin dc7__type__m_font($s_preset_id:null, $s_fontsize:null, $list_selector:null, $s_selector__base:'', $s_selector:'')
	{
	///h : 
	$list_selector__default: null;		///h :
	$map_selector: null;			///h :

	///h :
	$list_selector: (if(($list_selector == null), (), $list_selector));
	$list_selector__default: ();
  
	///h : add default classes
	@if($s_preset_id != null)
		{
		///h :
		$map_selector: _gdata('type__map_selector');
  
		///h :
		@if($s_preset_id == 'default')
			{
			///h :
			$list_selector__default: join($list_selector__default, _map($map_selector, 'base'));
			$list_selector__default: join($list_selector__default, _map($map_selector, 'p1'));
			$list_selector__default: join($list_selector__default, _map($map_selector, 'p2'));
			$list_selector__default: join($list_selector__default, _map($map_selector, 'p3'));
			$list_selector__default: join($list_selector__default, _map($map_selector, 'p4'));
			$list_selector__default: join($list_selector__default, _map($map_selector, 'p5'));
			$list_selector__default: join($list_selector__default, _map($map_selector, 'p6'));
			$list_selector__default: join($list_selector__default, _map($map_selector, 'h1'));
			$list_selector__default: join($list_selector__default, _map($map_selector, 'h2'));
			$list_selector__default: join($list_selector__default, _map($map_selector, 'h3'));
			$list_selector__default: join($list_selector__default, _map($map_selector, 'h4'));
			$list_selector__default: join($list_selector__default, _map($map_selector, 'h5'));
			$list_selector__default: join($list_selector__default, _map($map_selector, 'h6')); 
			}
		///h :
		@else
			{
			///h :
			$list_selector__default: join($list_selector__default, _map($map_selector, $s_preset_id));
			}
		}
  
	///h :
	$list_selector: join($list_selector__default, $list_selector);
	
	///h : 
	@include dc7__sass__m_selector($list_selector:$list_selector, $s_selector__base:$s_selector__base, $b_debug:false)
		{
		///h :
		//@error(&);

		///h :
		@if($s_preset_id == 'default')
			{
			///h :
			word-spacing: 0px;
			font-variant: normal;
			text-transform: none;
			text-decoration: none;
			text-indent: 0px;
			vertical-align: baseline;
			font-family: 'Arial';
			font-size: 16px;
			color: #{_gdata('system__color_black')};
			line-height: 1.20em;
			text-indent: 0px;
			}
 
		///h :
		@content;
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : methods - userinput
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : breakpoints - init system
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__userinput__m_mouse($s_preset_id:null, $s_userselect:none, $s_pointerevents:null, $s_cursor:null, $b_important:false)
	{
	///h : preset - noattract
	@if($s_preset_id == 'noattract')
		{
		$s_userselect: 'none';
		$s_pointerevents: 'none';
		$s_cursor: 'default';
		}
	///h :
	@if($s_preset_id == 'button')
		{
		$s_userselect: 'none';
		$s_pointerevents: 'all';
		$s_cursor: 'pointer';
		}
	///h :
	@if($s_preset_id == 'default')
		{
		$s_userselect: 'all';
		$s_pointerevents: 'all';
		$s_cursor: 'default';
		}
	///h : preset - noselect
	@if($s_preset_id == 'noselect')
		{
		$s_userselect: 'none';
		$s_pointerevents: 'all';
		$s_cursor: 'default';
		} 

	///h : user select
	@include dc7__sass__m_prefixes($s_property_name:'user-select', $s_property_value:$s_userselect, $b_important:$b_important);

	///h : pointer events
	pointer-events: #{$s_pointerevents};

	///h : cursor
	cursor: #{$s_cursor};
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : methods - userinput
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : transform - init system
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__fx__m_preset($s_id:'fade', $s_direction:'in', $s_duration:'0.4s', $s_delay:'0.00s', $s_ease:'easeOutExpo', $c_text:'#000000', $c_background:'#000000')
	{
	///h :
	$s_fx: null;		///h :

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : data

	///h :
	$s_fx: ('#{$s_id}#{$s_direction}');


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : fx - fade

	///h :
	@if($s_fx == 'fadein')
		{
		///h :
		@include dc7__layout__m_opacity($num_opacity:1);
		}
	///h :
	@else if($s_fx == 'fadeout')
		{
		///h :
		@include dc7__layout__m_opacity($num_opacity:0);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : fx - blendfrom

	///h :
	@else if($s_fx == 'blendfrombottomin')
		{
		///h :
		@include dc7__layout__m_opacity($num_opacity:1);
	
		///h :
		@include dc7__transform__m_properties($s_skew__x:'0deg', $s_skew__y:'0deg', $s_scale__x:'1.0', $s_scale__y:'1.0', $s_rotate:'0deg', $s_position__x:'0px', $s_position__y:'0px', $s_origin__x:'0px', $s_origin__y:'0px');
		}
	///h :
	@else if($s_fx == 'blendfrombottomout')
		{
		///h :
		@include dc7__layout__m_opacity($num_opacity:0);
		
		///h :
		@include dc7__transform__m_properties($s_skew__x:'0deg', $s_skew__y:'0deg', $s_scale__x:'1.0', $s_scale__y:'1.0', $s_rotate:'0deg', $s_position__x:'0px', $s_position__y:'50px', $s_origin__x:'0px', $s_origin__y:'0px');
		}
		
	///h :
	@else if($s_fx == 'blendfromleftin')
		{
		///h :
		@include dc7__layout__m_opacity($num_opacity:1);
	
		///h :
		@include dc7__transform__m_properties($s_skew__x:'0deg', $s_skew__y:'0deg', $s_scale__x:'1.0', $s_scale__y:'1.0', $s_rotate:'0deg', $s_position__x:'0px', $s_position__y:'0px', $s_origin__x:'0px', $s_origin__y:'0px');
		}
	///h :
	@else if($s_fx == 'blendfromleftout')
		{
		///h :
		@include dc7__layout__m_opacity($num_opacity:0);
		
		///h :
		@include dc7__transform__m_properties($s_skew__x:'0deg', $s_skew__y:'0deg', $s_scale__x:'1.0', $s_scale__y:'1.0', $s_rotate:'0deg', $s_position__x:'-50px', $s_position__y:'0px', $s_origin__x:'0px', $s_origin__y:'0px');
		}
	///h :
	@else if($s_fx == 'blendfromrightin')
		{
		///h :
		@include dc7__layout__m_opacity($num_opacity:1);
	
		///h :
		@include dc7__transform__m_properties($s_skew__x:'0deg', $s_skew__y:'0deg', $s_scale__x:'1.0', $s_scale__y:'1.0', $s_rotate:'0deg', $s_position__x:'0px', $s_position__y:'0px', $s_origin__x:'0px', $s_origin__y:'0px');
		}
	///h :
	@else if($s_fx == 'blendfromrightout')
		{
		///h :
		@include dc7__layout__m_opacity($num_opacity:0);
		
		///h :
		@include dc7__transform__m_properties($s_skew__x:'0deg', $s_skew__y:'0deg', $s_scale__x:'1.0', $s_scale__y:'1.0', $s_rotate:'0deg', $s_position__x:'50px', $s_position__y:'0px', $s_origin__x:'0px', $s_origin__y:'0px');
		}
		

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : timing an ease
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:$s_duration, $s_delay:$s_delay, $s_ease:$s_ease);
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : transform - init system
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__fx__m_filter($s_grayscale:null, $s_dropshadow:null, $s_opacity:null, $s_saturate:null, $s_sepia:null, $s_invert:null, $s_blur:null, $s_brightness:null, $s_contrast:null)
	{
	///h :
	$list_filter: ();

	///h : grayscale
	@if($s_grayscale != null)
		{
		$list_filter: append($list_filter, 'grayscale(#{$s_grayscale})');
		}

	///h : dropshadow
	@if($s_dropshadow != null)
		{
		$list_filter: append($list_filter, 'drop-shadow(#{$s_dropshadow})');
		}
		
	///h : opacity
	@if($s_opacity != null)
		{
		$list_filter: append($list_filter, 'opacity(#{$s_opacity})');
		}
		
	///h : saturate
	@if($s_saturate != null)
		{
		$list_filter: append($list_filter, 'saturate(#{$s_saturate})');
		}
		
	///h : sepia
	@if($s_sepia != null)
		{
		$list_filter: append($list_filter, 'sepia(#{$s_sepia})');
		}
		
	///h : invert
	@if($s_invert != null)
		{
		$list_filter: append($list_filter, 'invert(#{$s_invert})');
		}

	///h : blur
	@if($s_blur != null)
		{
		$list_filter: append($list_filter, 'blur(#{$s_blur})');
		}

	///h : brightness
	@if($s_brightness != null)
		{
		$list_filter: append($list_filter, 'brigthness(#{$s_brightness})');
		}
		
	///h : contrast
	@if($s_contrast != null)
		{
		$list_filter: append($list_filter, 'contrast(#{$s_contrast})');
		}

	///h : check if filter necessary
	@if(length($list_filter) > 0)
		{
		///h : join filter
		$s_filter: join($list_filter, ' ');

		///h :
		filter: #{$s_filter}; 
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : methods - animation
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : transform - init system
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__animation__m_embedStyles()
	{
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h :
	@include dc7__sass__m_keyframes($s_id:'dc7__animation__kf_rotation') 
		{
		///h :
		0%
			{
			///h :
			@include dc7__sass__m_prefixes($s_property_name:'transform', $s_property_value:'rotate(0deg)');
			}
		///h :
		100%
			{
			///h :
			@include dc7__sass__m_prefixes($s_property_name:'transform', $s_property_value:'rotate(360deg)');
			} 
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h :

	///h :
	@include dc7__sass__m_keyframes($s_id:'dc7__animation__kf_pop') 
		{
		///h :
		0%
			{
			///h :
			@include dc7__transform__m_properties($s_scale__x:'1.0', $s_scale__y:'1.0', $s_origin__x:'50%', $s_origin__y:'50%');
			}
		///h :
		20%
			{
			///h :
			@include dc7__transform__m_properties($s_scale__x:'1.3', $s_scale__y:'1.3', $s_origin__x:'50%', $s_origin__y:'50%');
			}
		///h :
		100%
			{
			///h :
			@include dc7__transform__m_properties($s_scale__x:'1.0', $s_scale__y:'1.0', $s_origin__x:'50%', $s_origin__y:'50%');
			}
		}
	}
	
  
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : transform - init system
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__animation__m_animation($s_id:'dc7__animation__kf_rotation', $s_duration:'0.4s', $s_repeat:'infinite', $s_ease:'linear', $s_delay:'0s', $b_acceleration:false, $s_direction:'forwards')
	{
	///h : get ease
	$map_animation_ease: _gdata('map_ease');
	$o_ease: _map($map_animation_ease, $s_ease);
	//$s_direction__intern: if(($s_direction == 'backwards'), 'normal', 'reverse');
	//$s_direction__intern: 'alternate-reverse';

	///h : animation
	@include dc7__sass__m_prefixes('animation-name', #{$s_id});
	@include dc7__sass__m_prefixes('animation-duration', #{$s_duration});
	@include dc7__sass__m_prefixes('animation-iteration-count', #{$s_repeat});
	@include dc7__sass__m_prefixes('animation-timing-function', #{$o_ease}); 
	@include dc7__sass__m_prefixes('animation-delay', #{$s_delay});
	//@include dc7__sass__m_prefixes('animation-direction', #{$s_direction__intern});

	@if($s_direction == 'forwards')
		{
	//	@include dc7__sass__m_prefixes('animation-direction', normal);
		//@include dc7__sass__m_prefixes('animation-fill-mode', 'forwards');
	//	animation-fill-mode: forwards;
		}
	@else
		{ 
	//	@include dc7__sass__m_prefixes('animation-direction', reverse);
		//@include dc7__sass__m_prefixes('animation-fill-mode', 'forwards');
	//	animation-fill-mode: reverse;
		}

	///h :
	@if($b_acceleration == true)
		{
		@include dc7__performance__m_forceHardwareAccelartion();
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : transform - init system
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 * 
 */
@mixin dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.40s', $b_acceleration:false, $s_delay:'0.00s', $s_ease:'easeOutExpo')
	{
	///h : get ease
	$map_animation_ease: _gdata('map_ease');
	$o_ease: _map($map_animation_ease, $s_ease);

	///h :
	transition-property: #{$s_property_name};
	transition-duration: #{$s_duration};
	transition-timing-function: #{$o_ease};
	transition-delay: #{$s_delay};

	///h :
	@if($b_acceleration == true)
		{
		///h :
		@include dc7__performance__m_forceHardwareAccelartion();
		}
	} 


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : transform - init system
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__animation__m_notransition()
	{
	///h :
	transition: none !important;

	///h :
	& *
		{
		transition: none !important;
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : performance - noflick
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : transform - init system
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__performance__m_forceHardwareAccelartion($b_willchange:false)
	{
	///h :
	//@if($b_willchange == true)
	//	{
		//will-change: transform;
	//	}

	///h : 
	outline: 1px solid transparent;

	///h : 
	@include dc7__sass__m_prefixes($s_property_name:'transform', $s_property_value:'translate3d(0,0,0.1)');
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : transform
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : transform - init system
/**
 * init content
 * initializing content and potential html rendering all requests
 * to other uris.
 *
 * @access public
 * @param
 * @return vault
 *
 */
@mixin dc7__transform__m_properties($s_skew__x:'0deg', $s_skew__y:'0deg', $s_scale__x:'1.0', $s_scale__y:'1.0', $s_rotate:'0deg', $s_position__x:'0px', $s_position__y:'0px', $s_origin__x:'0px', $s_origin__y:'0px')
	{
	///h :	
	@include dc7__sass__m_prefixes('transform-origin', '#{$s_origin__x} #{$s_origin__y}');
	
	///h :	
	@include dc7__sass__m_prefixes('transform', 'skewX(#{$s_skew__x}) skewY(#{$s_skew__y}) scale(#{$s_scale__x}, #{$s_scale__y}) rotate(#{$s_rotate}) translate(#{$s_position__x}, #{$s_position__y})');
	}
 

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :
@include dc7__m_initSystem();


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*

///h : condition
@include dc7__sass__m_selector($s_breakpoint_id:'sma', $s_selector__base:'.content', $s_mediaquery__from:null, $s_mediaquery_to:null, $s_mediaquery__type:only screen, $s_mediaquery_orientation:null, $a_mediaquery_conditions:null)
	{
	color: pink;
	} 
*/