/// Return last index of `$needle` in `$string`.
/// @since 1.2.0
/// @param {String} $string - string to search in
/// @param {String} $needle - substring to search for
/// @return {Number}
@function _ss-str-last-index($string, $needle) {
  $index: str-index($string, $needle);
  $result: $index;

  @if $index {
    @for $i from $index + str-length($needle) through str-length($string) {
      @if str-slice($string, $i, $i + str-length($needle) - 1) == $needle {
        $result: $i;
      }
    }
  }

  @return $result;
}
