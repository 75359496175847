/// Shuffle characters from `$string`.
/// @since 1.2.0
/// @param {String} $string - string to shuffle
/// @return {String}
@function _ss-str-shuffle($string) {
  @return str-implode(_ss-shuffle(str-explode($string)));
}

/// Shuffle a list
/// @access private
/// @param {List} $list - shuffle a list
/// @return {List}
@function _ss-shuffle($list) {
  @for $i from length($list) through 1 {
    $j: random(length($list) - 1) + 1;
    $tmp: nth($list, $i);
    $list: set-nth($list, $i, nth($list, $j));
    $list: set-nth($list, $j, $tmp);
  }

  @return $list;
}
