@function levenshtein($a, $b) {
  @if type-of($a) != "string" {
    @error "`str-count` function expecting a string for $a; #{type-of($a)} given.";
  }

  @if type-of($b) != "string" {
    @error "`str-count` function expecting a string for $b; #{type-of($b)} given.";
  }

  @return _ss-levenshtein($a, $b);
}
