@function char-at($string, $index) {
  @if type-of($string) != "string" {
    @error "`char-at` function expecting a string for $string; #{type-of($string)} given.";
  }

  @if type-of($index) != "number" {
    @error "`char-at` function expecting a number for $index; #{type-of($index)} given.";
  }

  @if $index < 1 or $index > str-length($string) {
    @error "Out of bounds $index for `char-at`.";
  }

  @return _ss-char-at($string, $index);
}
