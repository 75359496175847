@function str-replace($string, $search, $replace: '') {
  @if type-of($string) != "string" {
    @error "`str-replace` function expecting a string for $string; #{type-of($string)} given.";
  }

  @if type-of($search) != "string" {
    @error "`str-replace` function expecting a string for $search; #{type-of($search)} given.";
  }

  @if type-of($replace) != "string" {
    @error "`str-replace` function expecting a string for $replace; #{type-of($replace)} given.";
  }

  @return _ss-str-replace($string, $search, $replace);
}

