@function str-pad($string, $length, $pad: " ", $direction: left) {
  @if type-of($string) != "string" {
    @error "`str-pad` function expecting a string for $string; #{type-of($string)} given.";
  }

  @if type-of($pad) != "string" {
    @error "`str-pad` function expecting a string for $pad; #{type-of($pad)} given.";
  }

  @if type-of($length) != "number" {
    @error "`str-pad` function expecting a number for $length; #{type-of($length)} given.";
  }

  @if index(left right, $direction) == null {
    @error "`str-pad` function expecting either `left` or `right` for $direction: #{$direction} given.";
  }

  @return _ss-str-pad($string, $length, $pad, $direction);
}
