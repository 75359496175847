/// Repeat `$string` `$times` times.
/// @since 1.2.0
/// @param {String} $string - string to repeat
/// @param {Number} $times  - number of times to repeat $string
/// @return {String}
@function _ss-str-repeat($string, $times) {
  $result: "";

  @for $i from 1 through $times {
    $result: $result + $string;
  }

  @return $result;
}
