/// Count number of words in `$string`.
/// @since 1.2.0
/// @param {String} $string - string to check
/// @return {String}
@function _ss-str-word-count($string) {
    @if $string == "" {
        @return 0;
    }

    $string: str-trim($string);
    $words: ();
    $i: str-length($string);

    @while $i > 0 {
        @if str-slice($string, $i, $i) == " " {
            $words: append($words, str-slice($string, $i + 1));
            $string: str-slice($string, 1, $i - 1);
        }
        $i: $i - 1;
    }

    @return length(append($words, $string));
}
