@function str-repeat($string, $times) {
  @if type-of($string) != "string" {
    @error "`str-repeat` function expecting a string for $string; #{type-of($string)} given.";
  }

  @if type-of($times) != "number" {
    @error "`str-repeat` function expecting a number for $times; #{type-of($times)} given.";
  }

  @return _ss-str-repeat($string, $times);
}
