/// Replace `$search` with `$replace` in `$string`.
/// @since 1.2.0
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ("") - New value
/// @return {String} - Updated string
@function _ss-str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + _ss-str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
