/// Count the number of occurrences of `$needle` in `$string`.
/// @since 1.2.0
/// @param {String} $string - string
/// @param {String} $needle - substring to count in `$string`
/// @return {Number}
@function _ss-str-count($string, $needle) {
  $index: str-index($string, $needle);
  $result: if($index, 1, 0);

  @if $index {
    @for $i from $index + str-length($needle) through str-length($string) {
      @if str-slice($string, $i, $i + str-length($needle) - 1) == $needle {
        $result: $result + 1;
      }
    }
  }

  @return $result;
}
