	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic â€� massimo cardascia â€� www.plustic.de
///h : code language:	sass 3.4
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
////////////////butoonn//////////////////////////////////////////////////////////////////////////////////////////////

 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : id 0013 - rosen
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
   
  
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : importsâ€� dc7 library
@import '../../../../permanent.basic/Resources/Public/dc7/__dc7.scss';

///h : import om3 library
@import '../../../../permanent.basic/Resources/Public/Styles/__om3.scss';

///h : importsâ€�fontsâ€� Roboto and open sans
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700'); 

///h :
//@import 'font-awesome.css';
//@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.css');
 
 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : config
/////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : config - basic

///h : basic
$host__basic__s_color__1: '#375988' !global;				///h : host color 1 - blau (im logo)
$host__basic__s_color__1__dark: darken(#375988, 25.00%);		///h :
$host__basic__s_color__2: '#333333' !global;				///h : host color 2 - grey
$host__basic__s_color__3: '#F2F2F2' !global;				///h : host color 3 - litegrey
$host__basic__s_color__4: '#375988' !global;				///h : host color 4 -
$host__basic__s_color__5: '#7683BB' !global;				///h : host color 5 -
$host__basic__s_color__6: '#333333' !global;				///h : host color 6 - Akzentfarbe rot
$host__basic__s_color__white: '#FFFFFF' !global;			///h : host color white - weiss
$host__basic__s_color__black: '#111111' !global;			///h : host color black - schwarz
$host__basic__s_color__transparent: 'transparent' !global;		///h : host color white - weiss
$host__basic__s_color__main: $host__basic__s_color__1 !global;		///h : host color main
$host__basic__s_color__second: $host__basic__s_color__2 !global;	///h : host color second
$host__basic__s_color__warning: red !global;				///h : host color second
$host__basic__s_color__1__transparent: rgba(0, 0, 20, 0.30) !global;	///h :

 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : config - menu
 
///h :
$host__menu__s_border_color: $host__basic__s_color__white !global;			///h :
$host__menu__s_background_color: $host__basic__s_color__1 !global;			///h :
$host__menu__s_text_color: $host__basic__s_color__white !global;			///h :
$host__menu__s_text_color__hover: $host__basic__s_color__white !global;			///h :
$host__menu__s_contacticon_color: $host__basic__s_color__white !global;			///h :
$host__menu__s_contacticon_color__hover: $host__basic__s_color__white !global;		///h :
$host__menu__s_font_id: 'h6' !global;							///h :
$host__menu__s_burger_color: $host__basic__s_color__white !global;			///h :
$host__menu__s_burger_color__hover: $host__basic__s_color__white !global;		///h :
$host__menu__s_logowidth__1: '142px' !global;						///h :
 

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : config - icons

///h :
$host__icons__s_background_color: $host__basic__s_color__white !global;			///h :
$host__icons__s_foreground_color: $host__basic__s_color__1 !global;			///h :

 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : config - buttons
  
///h : config - buttons - 4 
$host__buttons__b1__s_background_color: $host__basic__s_color__1 !global;				///h :
$host__buttons__b1__s_text_color: $host__basic__s_color__white !global;				///h :
$host__buttons__b1__s_border_radius: '0px';							///h :
$host__buttons__b1__s_border_width: '2px';							///h :
$host__buttons__b1__s_border_color: $host__basic__s_color__white !global;			///h :
$host__buttons__b1__s_font_id: 'p1';								///h :
$host__buttons__b1__s_background_color__hover: $host__basic__s_color__white !global;		///h :
$host__buttons__b1__s_text_color__hover: $host__basic__s_color__1 !global;			///h :
$host__buttons__b1__s_border_color__hover: $host__basic__s_color__1 !global;			///h :
  
///h : config - buttons - 2
$host__buttons__b2__s_background_color: $host__basic__s_color__1 !global;			///h :
$host__buttons__b2__s_text_color: $host__basic__s_color__white !global;				///h :
$host__buttons__b2__s_border_radius: '0px';							///h :
$host__buttons__b2__s_border_width: '2px';							///h :
$host__buttons__b2__s_border_color: $host__basic__s_color__1 !global;				///h :
$host__buttons__b2__s_font_id: 'p1';								///h :
$host__buttons__b2__s_background_color__hover: $host__basic__s_color__white !global;		///h :
$host__buttons__b2__s_text_color__hover: $host__basic__s_color__1 !global;			///h :
$host__buttons__b2__s_border_color__hover: $host__basic__s_color__1 !global;			///h :

///h : config - buttons - 3
$host__buttons__b3__s_background_color: $host__basic__s_color__white !global;			///h :
$host__buttons__b3__s_text_color: $host__basic__s_color__1 !global;				///h :
$host__buttons__b3__s_border_radius: '0px';							///h :
$host__buttons__b3__s_border_width: '2px';							///h :
$host__buttons__b3__s_border_color: $host__basic__s_color__1 !global;				///h :
$host__buttons__b3__s_font_id: 'p1';								///h :
$host__buttons__b3__s_background_color__hover: $host__basic__s_color__1 !global;		///h :
$host__buttons__b3__s_text_color__hover: $host__basic__s_color__white !global;			///h :
$host__buttons__b3__s_border_color__hover: $host__basic__s_color__1 !global;			///h :

///h : config - buttons - 4
$host__buttons__b4__s_background_color: $host__basic__s_color__transparent !global;		///h :
$host__buttons__b4__s_text_color: $host__basic__s_color__white !global;				///h :
$host__buttons__b4__s_border_radius: '0px';							///h :
$host__buttons__b4__s_border_width: '2px';							///h :
$host__buttons__b4__s_border_color: $host__basic__s_color__white !global;			///h :
$host__buttons__b4__s_font_id: 'p1';								///h :
$host__buttons__b4__s_background_color__hover: $host__basic__s_color__white !global;		///h :
$host__buttons__b4__s_text_color__hover: $host__basic__s_color__1 !global;			///h :
$host__buttons__b4__s_border_color__hover: $host__basic__s_color__1 !global;			///h :
 

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : config - body

///h : body
$host__body__s_background_color: $host__basic__s_color__3 !global;				///h : host color 1 - rot (im logo)


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : config - type

///h :
$host__type__s_fontfamily__1: '"myriad-pro-semi-condensed", sans-serif';	///h : host font 1
$host__type__s_fontfamily__2: '"myriad-pro-semi-condensed", sans-serif';	///h : host font 2
$host__type__s_fontfamily__3: '"myriad-pro", sans-serif';			///h : host font 3
$host__type__s_fontfamily__4: '"roboto", sans-serif';				///h : host font 4

///h : type - p1
$host__type__p1__s_family: $host__type__s_fontfamily__3 !global;	///h : p1 - schriftart
$host__type__p1__s_size: '20px' !global;				///h : p1 - schriftgroesse
$host__type__p1__s_color: $host__basic__s_color__2 !global;		///h : p1 - textfarbe
$host__type__p1__s_lineheight: '1.2em' !global;				///h : p1 - zeilenabstand
$host__type__p1__s_weight: 'normal' !global;				///h : p1 - schriftdicke
$host__type__p1__s_letterspacing: '0px' !global;			///h : p1 - laufweite
$host__type__p1__s_style: 'normal' !global;				///h : p1 - fontstyle
$host__type__p1__s_transform: 'none' !global;				///h : p1 - fontstyle

///h : type - p2
$host__type__p2__s_family: $host__type__s_fontfamily__3 !global;	///h : p2 - schriftart
$host__type__p2__s_size: '18px' !global;				///h : p2 - schriftgroesse
$host__type__p2__s_color: $host__basic__s_color__2 !global;		///h : p2 - textfarbe
$host__type__p2__s_lineheight: '1.1em' !global;				///h : p2 - zeilenabstand
$host__type__p2__s_weight: 'normal' !global;;				///h : p2 - schriftdicke
$host__type__p2__s_letterspacing: '0px' !global;			///h : p2 - laufweite
$host__type__p2__s_style: 'normal' !global;				///h : p2 - fontstyle
$host__type__p2__s_transform: 'none' !global;				///h : p2 - fontstyle

///h : type - p3
$host__type__p3__s_family: $host__type__s_fontfamily__3 !global;	///h : p3 - schriftart
$host__type__p3__s_size: '16px' !global;				///h : p3 - schriftgroesse
$host__type__p3__s_color: $host__basic__s_color__2 !global;		///h : p3 - textfarbe
$host__type__p3__s_lineheight: '1.3em' !global;				///h : p3 - zeilenabstand
$host__type__p3__s_weight: 'normal' !global;;				///h : p3 - schriftdicke
$host__type__p3__s_letterspacing: '0px' !global;			///h : p3 - laufweite
$host__type__p3__s_style: 'normal' !global;				///h : p3 - fontstyle
$host__type__p3__s_transform: 'none' !global;				///h : p3 - fontstyle

///h : type - p4
$host__type__p4__s_family: $host__type__s_fontfamily__3 !global;	///h : p1 - schriftar
$host__type__p4__s_size: '14px' !global;				///h : p1 - schriftgroesse
$host__type__p4__s_color: $host__basic__s_color__2 !global;		///h : p1 - textfarbe
$host__type__p4__s_lineheight: '1.3em' !global;				///h : p1 - zeilenabstand
$host__type__p4__s_weight: '600' !global;				///h : p1 - schriftdicke
$host__type__p4__s_letterspacing: '0px' !global;			///h : p1 - laufweite
$host__type__p4__s_style: 'normal' !global;				///h : p1 - fontstyle
$host__type__p4__s_transform: 'none' !global;				///h : p1 - fontstyle

///h : type - p5
$host__type__p5__s_family: $host__type__s_fontfamily__2 !global;	///h : p2 - schriftart
$host__type__p5__s_size: '13px' !global;				///h : p2 - schriftgroesse
$host__type__p5__s_color: $host__basic__s_color__2 !global;		///h : p2 - textfarbe
$host__type__p5__s_lineheight: '1.1em' !global;				///h : p2 - zeilenabstand
$host__type__p5__s_weight: 'normal' !global;;				///h : p2 - schriftdicke
$host__type__p5__s_letterspacing: '0px' !global;			///h : p2 - laufweite
$host__type__p5__s_style: 'normal' !global;				///h : p2 - fontstyle
$host__type__p5__s_transform: 'none' !global;				///h : p2 - fontstyle
 
///h : type - p6
$host__type__p6__s_family: $host__type__s_fontfamily__4 !global;	///h : p3 - schriftart
$host__type__p6__s_size: '11px' !global;				///h : p3 - schriftgroesse
$host__type__p6__s_color: $host__basic__s_color__2 !global;		///h : p3 - textfarbe
$host__type__p6__s_lineheight: '1.1em' !global;				///h : p3 - zeilenabstand
$host__type__p6__s_weight: '600' !global;;				///h : p3 - schriftdicke
$host__type__p6__s_letterspacing: '0px' !global;			///h : p3 - laufweite
$host__type__p6__s_style: 'normal' !global;				///h : p3 - fontstyle
$host__type__p6__s_transform: 'none' !global;				///h : p3 - fontstyle
 
///h : type - h1
$host__type__h1__s_family: $host__type__s_fontfamily__1 !global;	///h : h1 - schriftart
$host__type__h1__s_size: '60px' !global;				///h : h1 - schriftgroesse
$host__type__h1__s_color: $host__basic__s_color__1 !global;		///h : h1 - textfarbe
$host__type__h1__s_lineheight: '0.95em' !global;			///h : h1 - zeilenabstand
$host__type__h1__s_weight: '300' !global;				///h : h1 - schriftdicke
$host__type__h1__s_letterspacing: '0px' !global;			///h : h1 - laufweite
$host__type__h1__s_style: 'normal' !global;				///h : h1 - fontstyle
$host__type__h1__s_transform: 'none' !global;				///h : h1 - fontstyle

///h : type - h2
$host__type__h2__s_family: $host__type__s_fontfamily__1 !global;	///h : h2 - schriftart
$host__type__h2__s_size: '50px' !global;				///h : h2 - schriftgroesse
$host__type__h2__s_color: $host__basic__s_color__1 !global;		///h : h2 - textfarbe
$host__type__h2__s_lineheight: '1em' !global;				///h : h2 - zeilenabstand
$host__type__h2__s_weight: 'normal' !global;;				///h : h2 - schriftdicke
$host__type__h2__s_letterspacing: '0px' !global;			///h : h2 - laufweite
$host__type__h2__s_style: 'normal' !global;				///h : h2 - fontstyle
$host__type__h2__s_transform: 'none' !global;				///h : h2 - fontstyle

///h : type - h3
$host__type__h3__s_family: $host__type__s_fontfamily__1 !global;	///h : h3 - schriftart
$host__type__h3__s_size: '40px' !global;				///h : h3 - schriftgroesse
$host__type__h3__s_color: $host__basic__s_color__1 !global;		///h : h3 - textfarbe
$host__type__h3__s_lineheight: '1em' !global;				///h : h3 - zeilenabstand
$host__type__h3__s_weight: 'normal' !global;;				///h : h3 - schriftdicke
$host__type__h3__s_letterspacing: '0px' !global;			///h : h3 - laufweite
$host__type__h3__s_style: 'normal' !global;				///h : h3 - fontstyle
$host__type__h3__s_transform: 'none' !global;				///h : h3 - fontstyle

///h : type - h4
$host__type__h4__s_family: $host__type__s_fontfamily__1 !global;	///h : h4 - schriftart
$host__type__h4__s_size: '30px' !global;				///h : h4 - schriftgroesse
$host__type__h4__s_color: $host__basic__s_color__1 !global;		///h : h4 - textfarbe
$host__type__h4__s_lineheight: '1.15em' !global;			///h : h4 - zeilenabstand
$host__type__h4__s_weight: 'normal' !global;;				///h : h4 - schriftdicke
$host__type__h4__s_letterspacing: '0px' !global;			///h : h4 - laufweite
$host__type__h4__s_style: 'normal' !global;				///h : h4 - fontstyle
$host__type__h4__s_transform: 'none' !global;				///h : h4 - fontstyle

///h : type - h5
$host__type__h5__s_family: $host__type__s_fontfamily__1 !global;	///h : h5 - schriftart
$host__type__h5__s_size: '20px' !global;				///h : h5 - schriftgroesse
$host__type__h5__s_color: $host__basic__s_color__1 !global;		///h : h5 - textfarbe
$host__type__h5__s_lineheight: '1.1em' !global;				///h : h5 - zeilenabstand
$host__type__h5__s_weight: 'normal' !global;;				///h : h5 - schriftdicke
$host__type__h5__s_letterspacing: '0px' !global;			///h : h5 - laufweite
$host__type__h5__s_style: 'normal' !global;				///h : h5 - fontstyle
$host__type__h5__s_transform: 'none' !global;				///h : h5 - fontstyle

///h : type - h6
$host__type__h6__s_family: $host__type__s_fontfamily__1 !global;	///h : h6 - schriftart
$host__type__h6__s_size: '14px' !global;				///h : h6 - schriftgroesse
$host__type__h6__s_color: $host__basic__s_color__white !global;		///h : h6 - textfarbe
$host__type__h6__s_lineheight: '1.2em' !global;				///h : h6 - zeilenabstand
$host__type__h6__s_weight: '600' !global;;				///h : h6 - schriftdicke
$host__type__h6__s_letterspacing: '0em' !global;			///h : h6 - laufweite
$host__type__h6__s_style: 'normal' !global;				///h : h6 - fontstyle
$host__type__h6__s_transform: 'uppercase' !global;			///h : h6 - fontstyle

///h :
$host__type__weight__bold: 'bold' !global;				///h :
 

////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : config - footer

///h :
$host__footer__s_background_color: $host__basic__s_color__2 !global;	///h :
$host__footer__s_font_id: 'h6' !global;					///h :
$host__footer__s_font_color: $host__basic__s_color__4 !global;		///h :

 
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : config - links

$host__links__text__s_color: $host__basic__s_color__6 !global;		///h :
$host__links__text__s_color__hover: $host__basic__s_color__6;		///h :
 

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : config - links

///h :
$host__layout__s_content__inner: '1150px';
$host__layout__col_blue__transparent: rgba(#275988, 0.9);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : embed
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :
@include om3__m_embedHostStyles();


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :
@mixin host__mx_item($s_preset_id:null, $s_width:'100%')
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		position: relative;
		display: inline-block;
	
		///h :
		border: 20px solid transparent;
		}
	}
	
///h :
@mixin host__mx_input()
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		padding: 10px 10px 10px 10px !important;
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : default
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


///h :
html
	{
	///h :
	max-width: 100vw;
//	overflow-y: hidden !important;
	background: #F2F2F2 !important;
	}

///h :
html._load__true
	{
	///h :
//	overflow-y: scroll !important;
	}

///h :
/*html._noscroll__true body,
html body
	{
	///h :
	overflow: hidden !important;
	overflow-x: hidden !important;
	overflow-y: hidden !important;
	}*/

/*///h :
.dc7__modal__bs_in_modal__true body
	{
	///h :
	overflow: visible !important;
	overflow-x: hidden !important;
	overflow-y: visible !important;
	}

//h :*/
/*html._noscroll__true .dc7__behavior__pagescrollup,
html._noscroll__true .dc7__behavior__pagescrollup::after
	{
	///h :
	display: none;
	}*/
 
///h :  
body
	{
	///h :
	background: #F2F2F2 !important;
	//display: none;
	}


///h :
html._load__true body
	{
	display: block;
	}

///h :
.content
	{
	///h :
//	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.30s', $s_delay:'0.00s', $s_ease:'linear');
	}


///h :
.content_page
	{
	///h :
	max-width: 100vw;

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		padding: 80px 0px 0px 0px;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		padding: 50px 0px 0px 0px;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		padding: 140px 0px 0px 0px;
		}

	///h :
//	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.60s', $s_delay:'0.00s', $s_ease:'easeOutQuad');
	}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : segment menu
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :
.content_footer a
	{
	text-decoration: underline !important;
	}
	
.content_footer a:hover
	{
	color: #333333;
	text-decoration: none !important;
	}
	
///h :
#content_footer .om3__segment__text p,
#content_footer .om3__segment__text a
	{
	///h :
	line-height: 1.9em !important;

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		max-width: 90%;
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : segment menu
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :
.om3__segment__menu
	{
	///h :
	width: 100%;
	height: 100%;
	position: fixed !important;
	z-index: 520;
	top: 0px;
	text-align: center;
	background: transparent;
	display: fixed;
	position: relative;
	padding: 0px 0px 0px 0px !important;
	pointer-events: none;

	///h : 
	@include dc7__grid__m_container();

	///h :
	@include dc7__layout__m_flexbox($s_horizontal:'center', $s_vertical:'center');

	///h :
	will-change: transform;

	///h :
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.80s', $s_delay:'0.00s', $s_ease:'expoEaseInOut');
	}

///h :
.neos-backend .om3__segment__menu
	{
	///h :
	display: none !important;
	}

///h :
.om3__segment__menu .om3__segment__menu_mobilenavigation
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		background-color: #{$host__layout__col_blue__transparent};
		position: fixed;
		width: 100%;
		height: 100vh;
		top: 0px;
		left: 0px;
		z-index: 450;

		///h :
		@include dc7__animation__m_transition($s_property_name:'opacity transform', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'expoEaseInOut');

		///h :
		@include dc7__layout__m_flexbox($s_horizontal:'center', $s_vertical:'center');
		}
	}

///h :
.om3__segment__menu .om3__segment__menu_mobilenavigation._active__true
	{
	///h :
	opacity: 1.00;
//	overflow-y: scroll;
	pointer-events: all !important; 
	}

///h :
.om3__segment__menu .om3__segment__menu_mobilenavigation._active__false
	{
	///h :
	opacity: 0.00;

	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'noattract');
	}

///h :
.om3__segment__menu .om3__segment__menu_mobilenavigation._active__false a,
	{
	///h :
	pointer-events: none !important;
	display: inline-block !important;
	}

///h :
.om3__segment__menu .om3__segment__menu_mobilenavigation._active__false a
	{
	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'noattract');
	}

///h :
.om3__segment__menu .om3__segment__menu_mobilenavigation_elements
	{
	///h :
	position: relative;
	padding: 80px 0px 40px 0px;

	///h :
	@include dc7__animation__m_transition($s_property_name:'opacity transform', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'expoEaseInOut');
	}

///h :
.om3__segment__menu .om3__segment__menu_mobilenavigation._active__true .om3__segment__menu_mobilenavigation_elements
	{
	///h :
	@include dc7__transform__m_properties($s_scale__x:'1.00', $s_scale__y:'1.00', $s_origin__x:'50.00%', $s_origin__y:'50.00%');
	}

///h :
.om3__segment__menu .om3__segment__menu_mobilenavigation._active__false .om3__segment__menu_mobilenavigation_elements
	{
	///h :
	@include dc7__transform__m_properties($s_scale__x:'1.10', $s_scale__y:'1.10', $s_origin__x:'50.00%', $s_origin__y:'50.00%');
	}

///h :
.om3__segment__menu .om3__segment__menu_mobilenavigation_elements > div
	{
	///h :
	display: block;
	padding: 10px 10px 10px 10px;
	}

///h :
.om3__segment__menu .om3__segment__menu_mobilenavigation_elements > div > a
	{
	///h :
	text-decoration: none;

	///h :
	@extend h6;

	///h :
	position: relative;
	color: white !important;
	cursor: pointer;
	font-size: 16px !important;

	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'button');
	}

///h :
.om3__segment__menu .om3__segment__menu_mobilenavigation_elements > div.current > a
	{
	///h :
	text-decoration: underline !important;
	}

///h :
.om3__segment__menu .om3__segment__menu_mobilenavigation_social
	{
	///h :
	display: inline-block;
	padding: 100px 10px 10px 10px;
	position: relative;
	top: 30px;
	}

///h :
.om3__segment__menu .om3__segment__menu_mobilenavigation_social_element
	{
	///h :
	width: 29px;
	display: inline-block;
	margin: 0px 5px 0px 5px;
	margin: 10px 15px 10px 15px;

	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'button');
	}

///h :
.om3__segment__menu .om3__segment__menu_mobilenavigation._active__false  .om3__segment__menu_mobilenavigation_social_element
	{
	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'noattract');
	}

///h :
.om3__segment__menu .om3__segment__menu_navigation
	{
	///h :
	@include dc7__grid__m_row();

	///h :
	width: 100%;
	height: 50px;
	background-color: #{$host__basic__s_color__1};
	border-bottom-width: 0px;
	border-bottom-style: solid;
	border-color: none !important;
	padding: 0px 0px 0px 0px;

	///h :
	@include dc7__layout__m_flexbox($s_vertical:'center');

	///h :
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'expoEaseInOut');
	}

///h :
.om3__segment__menu._topalign__true .om3__segment__menu_navigation
	{
	///h :
	width: 100%;

	///h :
	max-width: 100vw;
	}

///h :
.om3__segment__menu._topalign__false .om3__segment__menu_navigation
	{
	///h :
	max-width: #{$host__layout__s_content__inner} !important;
	width: 100%;
	height: 100px;

	///h :
	@include dc7__transform__m_properties($s_position__x:'0px', $s_position__y:'30px');
	}

///h :
.om3__segment__menu .om3__segment__menu_navigation_elements
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		white-space: nowrap;
		text-align: left;
		position: relative;
		left: 0px;
		padding: 0px 0px 0px 10px;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		display: none;
		}
	}

///h :
.om3__segment__menu .om3__segment__menu_navigation_elements_line
	{
	///h :
	position: absolute;
	height: 1px;
	width: 100px;
	background: white;
	bottom: 3px;
	}

///h :
.om3__segment__menu .om3__segment__menu_navigation_elements > div
	{
	///h :
	display: inline-block;
	}

///h :
.om3__segment__menu .om3__segment__menu_navigation_elements > div > a
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		text-decoration: none;

		///h :
		@extend h6;

		///h :
		position: relative;
		display: inline-block;
		padding: 10px 10px 10px 10px;
		color: white !important;
		top: -1px;

		///h :
		@include dc7__userinput__m_mouse($s_preset_id:'button');
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		font-size: 12px !important;
		}
	}

///h :
.om3__segment__menu .om3__segment__menu_navigation_burger
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		width: 60px;
		height: 40px;
		position: relative;
		display: none;
		z-index: 500;
		top: 1px;
		left: 15px;

		///h :
		@include dc7__userinput__m_mouse($s_preset_id:'button');
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		display: inline-block;
		}
	}
	

///h :
.om3__segment__menu .om3__segment__menu_navigation_burger_icon
	{
	///h :
	font-size: 35px;
	color: white;
	position: absolute;
	top: 2px;
	left: 12px;
	opacity: 0.00;

	///h :
	@include dc7__animation__m_transition($s_property_name:'opacity', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'expoEaseInOut');
	}
	
///h :
.om3__segment__menu .om3__segment__menu_navigation_burger_close
	{
	///h :
	font-size: 38px;
	color: white;
	position: absolute;
	top: 2px;
	left: 9px;
	opacity: 0.00;
	
	///h :
	@include dc7__animation__m_transition($s_property_name:'opacity', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'expoEaseInOut');
	}
	
///h :
.om3__segment__menu_navigation_burger._active__false .om3__segment__menu_navigation_burger_icon
	{
	///h :
	opacity: 1.00 !important;
	}
	
///h :
.om3__segment__menu_navigation_burger._active__true .om3__segment__menu_navigation_burger_close
	{
	///h :
	opacity: 1.00 !important;
	}

///h :
.om3__segment__menu .om3__segment__menu_navigation_topmeta
	{
	///h :
	position: absolute;
	top: -30px;
	right: 0px;
	padding: 5px 0px 5px 0px;

	///h :
	@include dc7__animation__m_transition($s_property_name:'opacity', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'expoEaseInOut');
	}

///h :
.om3__segment__menu._topalign__true .om3__segment__menu_navigation_topmeta
	{
	///h :
	opacity: 0.00;
	}

///h :
.om3__segment__menu._topalign__false .om3__segment__menu_navigation_topmeta
	{
	///h :
	opacity: 1.00;

	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'noattract');
	}

///h :
.om3__segment__menu .om3__segment__menu_navigation_topmeta_element
	{
	///h :
	width: 20px;
	display: inline-block;
	margin: 0px 5px 0px 5px;

	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'button');

	///h :
	@include dc7__animation__m_transition($s_property_name:'opacity', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'expoEaseInOut');
	}

///h :
.om3__segment__menu .om3__segment__menu_navigation_topmeta_element:hover
	{
	///h :
	opacity: 0.60;
	}

///h :
.om3__segment__menu .om3__segment__menu_navigation_meta
	{
	///h :
	height: 100%;
	width: 100%;
	top: 0px;
	position: absolute;
	right: 0px;
	text-align: right;
	z-index: 100;

	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'noattract');

	///h :
	@include dc7__layout__m_flexbox($s_horizontal:'right', $s_vertical:'center');
	}

///h :
.om3__segment__menu .om3__segment__menu_navigation_meta_social
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		opacity: 0.70;
		display: none !important;

		///h :
		@include dc7__animation__m_transition($s_property_name:'opacity', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'expoEaseInOut');
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		display: none;
		}
	}

///h :
.om3__segment__menu._topalign__false .om3__segment__menu_navigation_meta_social
	{
	///h :
	opacity: 0.00;

	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'noattract');
	}

///h :
.om3__segment__menu._topalign__false .om3__segment__menu_navigation_meta_social_element
	{
	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'noattract');
	}

///h :
.om3__segment__menu .om3__segment__menu_navigation_meta_social_element
	{
	///h :
	width: 16px;
	display: inline-block;
	margin: 0px 5px 0px 5px;

	///h :
	@include dc7__animation__m_transition($s_property_name:'opacity', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'expoEaseInOut');

	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'button');
	}

///h :
.om3__segment__menu .om3__segment__menu_navigation_meta_social_element:hover
	{
	///h :
	opacity: 0.60;
	}

///h :
.om3__segment__menu .om3__segment__menu_navigation_meta_logo
	{
	///h :
	padding: 0px 20px 0px 20px;
	width: 220px;
	margin: 0px 0px 0px 0px;
	right: 0px;
	display: inline-block;

	///h :
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'expoEaseInOut');

	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'button');
	}

///h :
.om3__segment__menu._topalign__true .om3__segment__menu_navigation_meta_logo
	{
	///h :
	width: 180px;
	}
///h :
.om3__segment__menu .om3__segment__menu_navigation_meta_logo img
	{
	///h :
	width: 100%;
	height: auto;
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : shadow
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :
.om3__segment__image
	{
	///h :
	display: inline-block;
	}

///h :
.om3__segment__image ._shadow
	{
	///h :
	position: absolute;
	width: calc(100% - 60px);
	height: calc(100% - 60px);
	margin-top: 20px;

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		display: none;
		}

	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'noattract');
	}

///h :
.om3__segment__image ._shadow._position__left
	{
	///h :
	left: -0px;
	}

///h :
.om3__segment__image ._shadow._position__right
	{
	///h :
	left: calc(30px + 30px);
	}

///h :
.neos-backend .om3__segment__image ._shadow
	{
	///h :
	opacity: 0.50;
	}

///h :
.om3__segment__image ._shadow ._shadow_box
	{
	///h :
	width: 0px;
	left: 100%;
	background-color: #ef3e4a;
	}

///h :
.om3__segment__image ._image_box
	{
	background: transparent;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	border: 30px solid transparent;
	background-clip: content-box;
	z-index: 20;
	}

///h :
.om3__segment__image ._image_box ._image_box_left,
.om3__segment__image ._shadow_box ._shadow_box_left
	{
	width: 0px;
	height: 0px;
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: white;
	}

///h :
.om3__segment__image._reveal__true .permanent-image-permimage > figure
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		opacity: 0.00;
		z-index: 10;
		position: relative;

		///h :
		@include dc7__userinput__m_mouse($s_preset_id:'noattract');
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		border: 10px solid transparent !important;
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		border: 10px solid transparent !important;
		}
	}

///h :
.neos-backend .om3__segment__image._reveal__true .permanent-image-permimage > figure
	{
	///h :
	opacity: 1.00 !important;
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : parallax
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :
.dc7__layout__zindex__1-5
	{
	///h :
	z-index: 10;
	}

	///h :
.dc7__layout__zindex__2-5
	{
	///h :
	z-index: 20;
	}

	///h :
.dc7__layout__zindex__3-5
	{
	///h :
	z-index: 30;
	}

///h :
.dc7__layout__zindex__4-5
	{
	///h :
	z-index: 40;
	}

///h :
.dc7__layout__zindex__5-5
	{
	///h :
	z-index: 50;
	}

///h :
.dc7__layout__position__relative
	{
	///h :align-content:
	position: relative !important;
	}

///h :
#content_page .dc7__layout__position__absolute
	{
	///h :
	position: absolute;
	left: auto;
	right: auto;
	top: auto;
	bottom: auto;
	}
	
///h :
#content
	{ 
	///h :
	}
	
///h :
#content_page
	{
	///h :
	//@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'linearEaseNone');
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-250.__s_contentverticalalign__top,
.om3__segment__clip.__s_contentforcedheight__100-200.__s_contentverticalalign__top,
.om3__segment__clip.__s_contentforcedheight__100-175.__s_contentverticalalign__top,
.om3__segment__clip.__s_contentforcedheight__100-150.__s_contentverticalalign__top,
.om3__segment__clip.__s_contentforcedheight__100-125.__s_contentverticalalign__top,
.om3__segment__clip.__s_contentforcedheight__100-100.__s_contentverticalalign__top,
.om3__segment__clip.__s_contentforcedheight__100-90.__s_contentverticalalign__top,
.om3__segment__clip.__s_contentforcedheight__100-80.__s_contentverticalalign__top,
.om3__segment__clip.__s_contentforcedheight__100-70.__s_contentverticalalign__top,
.om3__segment__clip.__s_contentforcedheight__100-60.__s_contentverticalalign__top,
.om3__segment__clip.__s_contentforcedheight__100-50.__s_contentverticalalign__top,
.om3__segment__clip.__s_contentforcedheight__100-40.__s_contentverticalalign__top,
.om3__segment__clip.__s_contentforcedheight__100-30.__s_contentverticalalign__top,
.om3__segment__clip.__s_contentforcedheight__100-20.__s_contentverticalalign__top,
.om3__segment__clip.__s_contentforcedheight__100-10.__s_contentverticalalign__top
	{
	///h :
	align-items: flex-start !important;

	///h :
	& > .neos-contentcollection
		{
		///h :
		top: 0px;
		bottom: auto;
		align-items: flex-start !important;
		align-self: flex-start !important;
		}
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-250.__s_contentverticalalign__middle,
.om3__segment__clip.__s_contentforcedheight__100-200.__s_contentverticalalign__middle,
.om3__segment__clip.__s_contentforcedheight__100-175.__s_contentverticalalign__middle,
.om3__segment__clip.__s_contentforcedheight__100-150.__s_contentverticalalign__middle,
.om3__segment__clip.__s_contentforcedheight__100-125.__s_contentverticalalign__middle,
.om3__segment__clip.__s_contentforcedheight__100-100.__s_contentverticalalign__middle,
.om3__segment__clip.__s_contentforcedheight__100-90.__s_contentverticalalign__middle,
.om3__segment__clip.__s_contentforcedheight__100-80.__s_contentverticalalign__middle,
.om3__segment__clip.__s_contentforcedheight__100-70.__s_contentverticalalign__middle,
.om3__segment__clip.__s_contentforcedheight__100-60.__s_contentverticalalign__middle,
.om3__segment__clip.__s_contentforcedheight__100-50.__s_contentverticalalign__middle,
.om3__segment__clip.__s_contentforcedheight__100-40.__s_contentverticalalign__middle,
.om3__segment__clip.__s_contentforcedheight__100-30.__s_contentverticalalign__middle,
.om3__segment__clip.__s_contentforcedheight__100-20.__s_contentverticalalign__middle,
.om3__segment__clip.__s_contentforcedheight__100-10.__s_contentverticalalign__middle
	{
	///h :
	align-items: center !important;

	///h :
	& > .neos-contentcollection
		{
		///h :
		top: 0px;
		bottom: auto;
		align-items: center !important;
		align-self: center !important;
		}
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-250.__s_contentverticalalign__bottom,
.om3__segment__clip.__s_contentforcedheight__100-200.__s_contentverticalalign__bottom,
.om3__segment__clip.__s_contentforcedheight__100-175.__s_contentverticalalign__bottom,
.om3__segment__clip.__s_contentforcedheight__100-150.__s_contentverticalalign__bottom,
.om3__segment__clip.__s_contentforcedheight__100-125.__s_contentverticalalign__bottom,
.om3__segment__clip.__s_contentforcedheight__100-100.__s_contentverticalalign__bottom,
.om3__segment__clip.__s_contentforcedheight__100-90.__s_contentverticalalign__bottom,
.om3__segment__clip.__s_contentforcedheight__100-80.__s_contentverticalalign__bottom,
.om3__segment__clip.__s_contentforcedheight__100-70.__s_contentverticalalign__bottom,
.om3__segment__clip.__s_contentforcedheight__100-60.__s_contentverticalalign__bottom,
.om3__segment__clip.__s_contentforcedheight__100-50.__s_contentverticalalign__bottom,
.om3__segment__clip.__s_contentforcedheight__100-40.__s_contentverticalalign__bottom,
.om3__segment__clip.__s_contentforcedheight__100-30.__s_contentverticalalign__bottom,
.om3__segment__clip.__s_contentforcedheight__100-20.__s_contentverticalalign__bottom,
.om3__segment__clip.__s_contentforcedheight__100-10.__s_contentverticalalign__bottom
	{
	///h :
	align-items: flex-end !important;

	///h :
	& > .neos-contentcollection
		{
		///h :
		top: 0px;
		bottom: auto;
		align-items: flex-end !important;
		align-self: flex-end !important;
		}
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-250.__s_childalign__left,
.om3__segment__clip.__s_contentforcedheight__100-200.__s_childalign__left,
.om3__segment__clip.__s_contentforcedheight__100-175.__s_childalign__left,
.om3__segment__clip.__s_contentforcedheight__100-150.__s_childalign__left,
.om3__segment__clip.__s_contentforcedheight__100-125.__s_childalign__left,
.om3__segment__clip.__s_contentforcedheight__100-100.__s_childalign__left,
.om3__segment__clip.__s_contentforcedheight__100-90.__s_childalign__left,
.om3__segment__clip.__s_contentforcedheight__100-80.__s_childalign__left,
.om3__segment__clip.__s_contentforcedheight__100-70.__s_childalign__left,
.om3__segment__clip.__s_contentforcedheight__100-60.__s_childalign__left,
.om3__segment__clip.__s_contentforcedheight__100-50.__s_childalign__left,
.om3__segment__clip.__s_contentforcedheight__100-40.__s_childalign__left,
.om3__segment__clip.__s_contentforcedheight__100-30.__s_childalign__left,
.om3__segment__clip.__s_contentforcedheight__100-20.__s_childalign__left,
.om3__segment__clip.__s_contentforcedheight__100-10.__s_childalign__left
	{
	///h :
//	justify-content: flex-start !important;

	///h :
	& > .neos-contentcollection
		{
		///h :
/*		left: 0px;
		right: auto;*/
		justify-content: flex-start !important;
		}
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-250.__s_childalign__center,
.om3__segment__clip.__s_contentforcedheight__100-200.__s_childalign__center,
.om3__segment__clip.__s_contentforcedheight__100-175.__s_childalign__center,
.om3__segment__clip.__s_contentforcedheight__100-150.__s_childalign__center,
.om3__segment__clip.__s_contentforcedheight__100-125.__s_childalign__center,
.om3__segment__clip.__s_contentforcedheight__100-100.__s_childalign__center,
.om3__segment__clip.__s_contentforcedheight__100-90.__s_childalign__center,
.om3__segment__clip.__s_contentforcedheight__100-80.__s_childalign__center,
.om3__segment__clip.__s_contentforcedheight__100-70.__s_childalign__center,
.om3__segment__clip.__s_contentforcedheight__100-60.__s_childalign__center,
.om3__segment__clip.__s_contentforcedheight__100-50.__s_childalign__center,
.om3__segment__clip.__s_contentforcedheight__100-40.__s_childalign__center,
.om3__segment__clip.__s_contentforcedheight__100-30.__s_childalign__center,
.om3__segment__clip.__s_contentforcedheight__100-20.__s_childalign__center,
.om3__segment__clip.__s_contentforcedheight__100-10.__s_childalign__center
	{
	///h :
//	justify-content: center !important;
//	display: inline-flex !important;
//	text-align: right;

	///h :
	& > .neos-contentcollection
		{
		///h :
/*		left: 0px;
		right: 0px;*/
	//	right: auto;
		justify-content: center !important;
		}
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-250.__s_childalign__right,
.om3__segment__clip.__s_contentforcedheight__100-200.__s_childalign__right,
.om3__segment__clip.__s_contentforcedheight__100-175.__s_childalign__right,
.om3__segment__clip.__s_contentforcedheight__100-150.__s_childalign__right,
.om3__segment__clip.__s_contentforcedheight__100-125.__s_childalign__right,
.om3__segment__clip.__s_contentforcedheight__100-100.__s_childalign__right,
.om3__segment__clip.__s_contentforcedheight__100-90.__s_childalign__right,
.om3__segment__clip.__s_contentforcedheight__100-80.__s_childalign__right,
.om3__segment__clip.__s_contentforcedheight__100-70.__s_childalign__right,
.om3__segment__clip.__s_contentforcedheight__100-60.__s_childalign__right,
.om3__segment__clip.__s_contentforcedheight__100-50.__s_childalign__right,
.om3__segment__clip.__s_contentforcedheight__100-40.__s_childalign__right,
.om3__segment__clip.__s_contentforcedheight__100-30.__s_childalign__right,
.om3__segment__clip.__s_contentforcedheight__100-20.__s_childalign__right,
.om3__segment__clip.__s_contentforcedheight__100-10.__s_childalign__right
	{
	///h :
//	justify-content: flex-end !important;

	///h :
	& > .neos-contentcollection
		{
		///h :
		justify-content: flex-end !important;
		}
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-250,
.om3__segment__clip.__s_contentforcedheight__100-200,
.om3__segment__clip.__s_contentforcedheight__100-175,
.om3__segment__clip.__s_contentforcedheight__100-150,
.om3__segment__clip.__s_contentforcedheight__100-125,
.om3__segment__clip.__s_contentforcedheight__100-100,
.om3__segment__clip.__s_contentforcedheight__100-90,
.om3__segment__clip.__s_contentforcedheight__100-80,
.om3__segment__clip.__s_contentforcedheight__100-70,
.om3__segment__clip.__s_contentforcedheight__100-60,
.om3__segment__clip.__s_contentforcedheight__100-50,
.om3__segment__clip.__s_contentforcedheight__100-40,
.om3__segment__clip.__s_contentforcedheight__100-30,
.om3__segment__clip.__s_contentforcedheight__100-20,
.om3__segment__clip.__s_contentforcedheight__100-10
	{
	///h :
	display: inline-flex;
	justify-content: center;
	display: inline-flex;
	flex-direction: row;
	flex: 1;
	min-width: 0;
	min-height: 0;

	///h :
	& > .neos-contentcollection
		{
		///h :
		position: absolute !important;
		top: auto;
		left: 0px;
		right: 0px;
		bottom: auto;
		margin-left: auto;
		margin-right: auto;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		height: 100% !important;
		}
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-250
	{
	padding-top: 250% !important;
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-200
	{
	padding-top: 200% !important;
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-175
	{
	padding-top: 175% !important;
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-150
	{
	padding-top: 150% !important;
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-125
	{
	padding-top: 120% !important;
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-100
	{
	padding-top: 100% !important;
	}


///h :
.om3__segment__clip.__s_contentforcedheight__100-90
	{
	padding-top: 90% !important;
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-80
	{
	padding-top: 80% !important;
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-70
	{
	padding-top: 80% !important;
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-60
	{
	padding-top: 60% !important;
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-50
	{
	padding-top: 50% !important;
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-40
	{
	padding-top: 40% !important;
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-30
	{
	padding-top: 30% !important;
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-20
	{
	padding-top: 20% !important;
	}

///h :
.om3__segment__clip.__s_contentforcedheight__100-10
	{
	padding-top: 10% !important;
	}


///h :
.om3__segment__clip.__s_contentverticalalign__center
	{
	///h :
	& > .neos-contentcollection
		{
		display: inline-flex;
		align-items: center;
		}
	}

///h :
.om3__segment__clip.__s_contentverticalalign__bottom
	{
	///h :
	& > .neos-contentcollection
		{
		display: inline-flex;
		align-items: flex-end;
		}
	}

///h :
.content._noanimation,
.content._noanimation *
	{
	///h :
	//transition: none !important;
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : text colors
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :
.om3__segment ._host_headline_color__blue > h1,
.om3__segment ._host_headline_color__blue > div > h1,
.om3__segment ._host_headline_color__blue > h2,
.om3__segment ._host_headline_color__blue > div > h2,
.om3__segment ._host_headline_color__blue > h3,
.om3__segment ._host_headline_color__blue > div > h3,
.om3__segment ._host_headline_color__blue > h4,
.om3__segment ._host_headline_color__blue > div > h4,
.om3__segment ._host_headline_color__blue > h5,
.om3__segment ._host_headline_color__blue > div > h5,
.om3__segment ._host_headline_color__blue > h6,
.om3__segment ._host_headline_color__blue > div > h6,
.om3__segment ._host_headline_color__blue > a
	{
	///h :
	color: #{$host__basic__s_color__1} !important;
	}

///h :
.om3__segment ._host_headline_color__grey > h1,
.om3__segment ._host_headline_color__grey > div > h1,
.om3__segment ._host_headline_color__grey > h2,
.om3__segment ._host_headline_color__grey > div > h2,
.om3__segment ._host_headline_color__grey > h3,
.om3__segment ._host_headline_color__grey > div > h3,
.om3__segment ._host_headline_color__grey > h4,
.om3__segment ._host_headline_color__grey > div > h4,
.om3__segment ._host_headline_color__grey > h5,
.om3__segment ._host_headline_color__grey > div > h5,
.om3__segment ._host_headline_color__grey > h6,
.om3__segment ._host_headline_color__grey > div > h6,
.om3__segment ._host_headline_color__grey a
	{
	///h :
	color: #{$host__basic__s_color__2} !important;
	}

///h :
.om3__segment ._host_headline_color__white > h1,
.om3__segment ._host_headline_color__white > div > h1,
.om3__segment ._host_headline_color__white > h2,
.om3__segment ._host_headline_color__white > div > h2,
.om3__segment ._host_headline_color__white > h3,
.om3__segment ._host_headline_color__white > div > h3,
.om3__segment ._host_headline_color__white > h4,
.om3__segment ._host_headline_color__white > div > h4,
.om3__segment ._host_headline_color__white > h5,
.om3__segment ._host_headline_color__white > div > h5,
.om3__segment ._host_headline_color__white > h6,
.om3__segment ._host_headline_color__white > div > h6
	{
	///h :
	color: #{$host__basic__s_color__white} !important;
	}

///h :
.om3__segment._host_copy_color__blue,
.om3__segment._host_copy_color__blue p,
.om3__segment._host_copy_color__blue li
	{
	///h :
	color: #{$host__basic__s_color__1} !important;
	}

///h :
.om3__segment._host_copy_color__grey,
.om3__segment._host_copy_color__grey p,
.om3__segment._host_copy_color__grey li,
.om3__segment._host_copy_color__grey a
	{
	///h :
	color: #{$host__basic__s_color__2} !important;
	}

///h :
.om3__segment._host_copy_color__white,
.om3__segment._host_copy_color__white p,
.om3__segment._host_copy_color__white li,
.om3__segment._host_copy_color__white a
	{
	///h :
	color: #{$host__basic__s_color__white} !important;
	}

.om3__segment
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		margin: 0px 0px 0px 0px !important;
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		}
	}

///h :
._self_limited
	{
	///h :
	max-width: 1150px;
	}

///h :
.__s_segment_id__newslistlinks
	{
	///h :
//	display: none !important;
	}

///h :
.neos-backend .__s_segment_id__newslistlinks
	{
	///h :
//	display: inline-block !important;
	}

///h :
._packery
	{
	///h :x
	position: absolute !important;
	}

///h :
._packery_quicklink
	{
	//box-sizing: content-box !important;
	}


///h :
.om3__segment h1 + h3
	{
	///h :
	margin-top: 0.30em;
	}

///h :
.dc7__modal .dc7__modal_background
	{
	///h :
	background-color: rgba(#375988, 0.95) !important;
	}

///h :
.dc7__modal .dc7__modal_content
	{
	///h :
	-webkit-box-shadow: 10px 10px 49px -10px rgba(0, 0, 0, 1.00);
	-moz-box-shadow: 10px 10px 49px -10px rgba(0, 0, 0, 1.0);
	box-shadow: 10px 10px 49px -10px rgba(0, 0, 0, 1.0);
	}

///h :
.om3__segment__text.p1 p
	{
	///h :
	@extend .p1;
	}

///h :
.om3__segment__text.p2 p
	{
	///h :
	@extend .p2;
	}

///h :
.om3__segment__text.p3 p
	{
	///h :
	@extend .p3;
	}

///h :
.om3__segment__text.p4 p
	{
	///h :
	@extend .p4;
	}

///h :
.om3__segment__text.p5 p
	{
	///h :
	@extend .p5;
	}

///h :
.om3__segment__text.p6 p
	{
	///h :
	@extend .p6;
	}

///h :
.fader
	{
	///h :
	display: none;
	position: fixed;
	z-index: 10000;
	width: 100%;
	height: 100%;
	background: #F2F2F2;
	top: 0px;
	left: 0px;

	///h :
	@include dc7__layout__m_flexbox($s_horizontal:'center', $s_vertical:'center');

	///h :
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.40s', $s_delay:'0.01s', $s_ease:'expoEaseInOut');
	}

///h :
html._load__true .fader
	{
	///h :
	opacity: 0.00;

	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'noattract');
	}

///h :
html .p1 p
	{
	///h :
	@extend .p1;
	}

///h :
html .p2 p
	{
	///h :
	@extend .p2;
	}

///h :
html .p3 p
	{
	///h :
	@extend .p3;
	}


///h :
html .p4 p
	{
	///h :
	@extend .p4;
	}


///h :
html .p5 p
	{
	///h :
	@extend .p5;
	}

///h :
html .p6 p
	{
	///h :
	@extend .p6;
	}

///h :
.om3__segment__text > div
	{
	///h :
	max-width: 100000px !important;
	}
 
///h :
._same_height__true
	{
	///h :
//	border: 1px solid red;
	}

///h :
.om3__segment__quicklink
	{
	///h :
	}

///h :
.om3__segment__quicklink > div
	{
	///h :
	padding-bottom: 100px !important;
	}

///h :
.om3__segment__quicklink .om3__segment__button 
	{
	///h :
	padding: 0px !important;
	position: absolute !important;
	left: 0px !important;
	right: 0px !important;
	margin: 0px auto 0px auto;
	bottom: 40px !important;
	top: auto;
	width: calc(100% - 60px) !important;
	}
	
///h :
.om3__segment__quicklink .om3__segment__button .om3__segment__button_button
	{
	///h :
	white-space: normal !important;
	color: red !important;
	}

///h :
#content_page .dc7__behavior__packery_item
	{
	///h :
	position: absolute !important;
	}

///h :
.om3__segment:not(.om3__segment__clip)
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		padding: 20px 20px 20px 20px;
		}
	}

///h :
.om3__segment__clip.__b_fxshadow__true > .neos-contentcollection
	{
	///h :
	-webkit-box-shadow: 30px 30px 50px 0px rgba(0, 0, 0, 0.28);
	-moz-box-shadow: 30px 30px 50px 0px rgba(0, 0, 0, 0.28);
	box-shadow: 30px 30px 50px 0px rgba(0, 0, 0, 0.28);
	}


///h :
#content_page .om3__segment__quicklink
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		border: 1vw solid transparent;
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		border: 20px solid transparent;
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		border: 1vw solid transparent;
		}
	}

///h :
.om3__segment__quicklink > #quicklink-color
	{
	///h :
	display: inline-flex;
	justify-content: center;
	align-items: center;
	}

///h :
.om3__segment__quicklink > #quicklink-color > .om3__segment__button
	{
	///h :
	left: 0px !important;
	right: 0px !important;
	margin-left: auto !important;
	margin-right: auto !important;
	}


///h :
.om3__segment__newslist
	{
	box-sizing: border-box;

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		border: 30px solid transparent;
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		border: 20px solid transparent;
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		border: 20px solid transparent;
		}
	}
	
///h :
.om3__segment__newslist h2
	{
	///h :
	width: 100.00%;
	text-align: center !important;
	}

///h :
.om3__segment__newslist .tile
	{
	///h :
	border: none !important;
	box-sizing: border-box !important;
	background: transparent;

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		padding: 10px !important;
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		padding: 20px 0px 20px 0px !important;
		}
	}

///h :
.om3__segment__newslist .om3__segment__newslist_title
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		top: 20px;
		left: 10px;
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		top: 20px;
		left: 10px;
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		top: 20px;
		left: 10px;
		}

	}

///h :
.om3__segment__newslist .news_more_button
	{
	///h :
	margin-right: -5px;
	padding: 0px !important;
	top: auto;
	right: 0px;
	bottom: 5px;
	}

///h :
.om3__segment__clip.__b_header_image
	{
	///h :
	}

///h :
.om3__segment__clip.__b_header_image > .neos-contentcollection > * > *
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		display: none;
		}
	}

///h :
#content .__b_header_image__prepend
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		top: 10px;
		display: none;
		width: 100%;
		padding: 0px 0px 0px 0px !important;
		max-width: 1150px;
		margin: 0px 0px 0px 0px !important;
		top: 0px !important;
		left: 0px !important;
		padding-bottom: 40px !important;
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		display: inline-block;
		}
	}

///h :
#content .__b_header_image__prepend .om3__segment__button
	{
	///h :align-content:
	top: -10px;
	}

///h :
#content .__b_header_image__prepend > *
	{
	///h :
	width: 100 !important;
	}

///h :
#content .__b_header_image__prepend h1,
#content .__b_header_image__prepend h2,
#content .__b_header_image__prepend h3,
#content .__b_header_image__prepend h4,
#content .__b_header_image__prepend h5,
#content .__b_header_image__prepend h6
	{
	///h :
	color: #{$host__basic__s_color__1} !important;
	}

///h :
#content .__b_header_image__prepend p
	{
	///h :
	color: #{$host__basic__s_color__2} !important;
	}

///h :
html.dc7__animation__notransition *
	{
	///h :
/*	-webkit-transition: none !important;
	-moz-transition: none !important;
	-o-transition: none !important;
	-ms-transition: none !important;
	transition: none !important;*/
	}

.content
	{
	///h :
	//@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.80s', $s_delay:'0.00s', $s_ease:'Quad.easeInOut');
	}



	
body:not(.neos-backend) .__bs_visible_on_sma__false
	{
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		display: none !important;
		}
	}

body:not(.neos-backend) .__bs_visible_on_tab__false
	{
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		display: none !important;
		}
	}
	
body:not(.neos-backend) .__bs_visible_on_des__false
	{
	@include dc7__sass__m_selector($s_breakpoint_id:'des')
		{ 
		display: none !important;
		}
	}
	
.neos-backend .__bs_visible_on_sma__false,
.neos-backend .__bs_visible_on_tab__false,
.neos-backend .__bs_visible_on_des__false
	{
	///h :
	display: inline-block !important;
	}

///h :
.__fxstretch
	{
	///h :
	@include dc7__animation__m_transition($s_property_name:'transform', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'easeOutQuad');
	}

	
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : product details
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


///h :
.host__productdetail
	{
	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'noattract');
	}

///h :
.host__productdetail._active__true
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		position: relative !important;
		height: auto;
		margin: 30px 0px 0px 0px;
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		position: relative !important;
		height: auto;
		margin: 30px 0px 0px 0px;
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		position: fixed !important;
		left: 0px;
		top: -10px !important;
		width: 100%;
		height: 100%;
		z-index: 100;
		}
	}

///h :
.host__productdetail._active__true > .neos-contentcollection
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		position: relative !important;
		
		///h :
		display: inline-block;
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		position: relative !important;
		
		///h :
		display: inline-block;
		}
	
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{	
		///h :
		position: absolute !important;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;

		///h :
		@include dc7__layout__m_flexbox($s_vertical:'center');
		}
	}

///h :
.host__productdetail__left
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		width: 100%;
		height: auto;
		margin: 0px 0px 20px 0px;
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		width: 100%;
		height: auto;
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{	
		///h : 
		left: 0px;
		min-height: calc(70.00%);
		height: 70.00%;
		width: 20%;
		text-align: right;
		top: 0px;
		bottom: 0px;
		margin: auto 0px auto 0px;
		padding: 0px 20px 0px 20px;
		min-width: 240px;
		}
	}
	
///h :
.host__productdetail__left > .neos-contentcollection
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h : 
		text-align: left !important; 
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h : 
		text-align: left !important; 
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h : 
		text-align: right !important; 
		}
	}
	
///h :
.host__productdetail__left > .neos-contentcollection > .om3__segment
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h : 
		text-align: left !important; 
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h : 
		text-align: left !important; 
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h : 
		text-align: right !important; 
		}
	}
	
///h :
.host__productdetail__left > .neos-contentcollection > .om3__segment > .neos-contentcollection
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h : 
		text-align: left !important; 
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h : 
		text-align: left !important; 
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h : 
		text-align: right !important; 
		}
	}
	
///h :
.host__productdetail__left p
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h : 
		text-align: left !important; 
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h : 
		text-align: left !important; 
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h : 
		width: 100% !important;
		text-align: right !important; 
		}
	}
	
///h :
.host__productdetail__left > .neos-contentcollection > .om3__segment__text
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h : 
		text-align: left !important; 
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h : 
		text-align: left !important; 
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h : 
		width: 100% !important;
		text-align: right !important;
		padding-right: 5px;
		}
	}
	
///h :
.host__productdetail__right
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		position: relative !important;
		width: 100%;
		height: auto;
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		position: relative !important;
		width: 100%;
		height: auto;
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		min-height: 70.00%;
		height: 70.00%;
		width: 20.00%;
		min-width: 240px;
		right: 0px !important;
		position: absolute !important;
		left: auto !important;
		top: 0px;
		bottom: 0px;
		margin: auto 0px auto 0px;
		text-align: left;
		padding: 0px 20px 0px 20px;
		}
	}
	
///h :
.host__productdetail__right > .neos-contentcollection > .om3__segment__text
	{
	///h :
	padding-left: 5px !important;
	padding-right: 0px !important;
	padding-bottom: 10px !important;
	}
	

///h :
._original__true
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h : 
	 	display: none !important; 
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		display: none !important;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'des')
		{
		///h :
		display: inline-block;
		}
	}
	
///h :
.host__productdetail ._clone__true
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		display: inline-block;
		text-align: center;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		display: inline-block;
		text-align: center;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'des')
		{
		///h :
		display: none;
		}
	}
	
	
///h :
.host__productdetail ._clone__true .om3__segment__image
	{ 
	///h :
	opacity: 1.00 !important;
	
	///h : 
	@include dc7__transform__m_properties($s_position__x:'0px', $s_position__y:'0px', $s_scale__x:'1.00', $s_scale__y:'1.00');
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h :

///h :
.tooltipster-base
	{
	///h :
	}

///h :
.tooltipster-base .tooltipster-arrow-border
	{
	///h :
	display: none;
	}

///h :
.tooltipster-base .tooltipster-arrow-background
	{
	///h :
	background: none;
	border-color: #{$host__basic__s_color__1} !important;
	display: none;
	}

///h :
.tooltipster-base .tooltipster-box
	{
	///h :
	border: none;
	background: #{$host__basic__s_color__1};
	border-radius: 0px;
	}

///h :
.tooltipster-base .tooltipster-content
	{
	///h :
	padding: 5px 10px 8px 10px;

	///h :
	font-family: 'myriad-pro-semi-condensed', sans-serif;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 1.00em;
	}

///h :
._tooltip__left,
._tooltip__right
	{
	///h :
	cursor: pointer;
	display: inline-block;
	margin: 6px 6px 6px 6px !important;
	border: 1px solid #{$host__basic__s_color__1};
	}

///h :
._productdetailbox__left
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		width: 60px;
		height: auto;
		margin: 0px 10px 10px 0px !important;
		display: inline-block !important;
		}
		///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		width: 60px;
		height: auto;
		margin: 0px 10px 10px 0px !important;
		display: inline-block !important;
		}	
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		width: 60px;
		height: auto;
		}
	}
	
///h :
.host__productdetail__left > .neos-contentcollection > .om3__segment__text
	{
	///h :
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-bottom: 10px !important;
	}

///h :
._productdetailbox__left img
	{
	}

///h :
._tooltip__left,
._tooltip__right
	{
	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'button');
	pointer-events: all !important; 
	}

///h :
._productdetailbox__right
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		width: 80px !important;
		height: 30px !important;
		margin: 0px 10px 10px 0px !important;
		display: inline-block !important;
		position: relative;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		width: 80px !important;
		height: 30px !important;
		margin: 0px 10px 10px 0px !important;
		display: inline-block;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		width: 80px !important;
		height: 30px !important;
		display: inline-block !important;
		
		///h :
		@include dc7__userinput__m_mouse($s_preset_id:'button');
		}
	}

	
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : storefinder
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :
.om3__segment__storefinder
	{
	///h :
	text-align: center;
	background-color: white;
	padding: 60px 0px 0px 0px !important;
	margin: -20px 0px 0px 0px;
	}
	
	
///h : 
.om3__segment__storefinder__map
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		height: 0px;
		width: 100%;
		max-width: 1400px;
		max-height: 450px;
		display: inline-block;
		}

	///h :
	@include dc7__sass__m_selector($s_selector__base:'.om3__segment__storefinder._state__intro')
		{
		///h :
	//	display: none;
		}
	}
	
///h : 
.om3__segment__storefinder._results__false .om3__segment__storefinder__map
	{
	///h :
	display: none;
	}
	
///h : 
.om3__segment__storefinder__map__content
	{
	///h :
	}
	
///h :
.om3__segment__storefinder__content
	{
	///h :
	}

///h :
.om3__segment__storefinder__content__wrapper
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		padding: 40px 20px 40px 20px;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		padding: 40px 40px 40px 40px;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		width: 100%;
		max-width: 1400px;
		padding: 0px 100px 60px 100px;
		display: inline-block;
		}

	///h :
	@include dc7__sass__m_selector($s_selector__base:'.om3__segment__storefinder._state__intro')
		{
		///h :
		@include dc7__layout__m_flexbox($s_horizontal:'center', $s_vertical:'center');
		}

	///h :
	@include dc7__sass__m_selector($s_selector__base:'.om3__segment__storefinder._state__normal')
		{
		
		}
	}

///h :
.om3__segment__storefinder__content__wrapper__loader
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		top: 85px;
		left: 0px;
		right: 0px;
		margin: 0px auto 0px auto;
		position: absolute;
		min-height: 65px;
		opacity: 0;
		} 
	} 

///h :
.om3__segment__storefinder__content__wrapper__left
	{
           	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h : 
		@include host__mx_item();

		///h :
		width: 69.00%;
		text-align: left;
		}
                
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		width: 100%;
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		width: 100%;
		}
	


	///h :
	@include dc7__sass__m_selector($s_selector__base:'.om3__segment__storefinder._state__intro')
		{
		///h :
/*		width: 100%;
		height: 350px;*/
		}
	}
	
///h :
.om3__segment__storefinder__content__wrapper__left__container
	{
	///h :
	}
	
///h :
.om3__segment__storefinder__content__wrapper__left__container__date
	{
	///h :
	@include dc7__sass__m_selector($s_selector__base:'.om3__segment__storefinder._state__intro')
		{
		///h :
		display: none;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		display: none;
		}
	}
	
///h :
.om3__segment__storefinder__content__wrapper__left__container__date__select
	{
	}

///h :
.om3__segment__storefinder__content__wrapper__left__container__response
	{
	///h :
	@include dc7__sass__m_selector($s_selector__base:'.om3__segment__storefinder._state__intro')
		{
		///h :
		position: absolute;
		bottom: -10px;
		left: 2px;
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		margin: 0px 0px 0.50em 0px;
		}
	}

///h :
.om3__segment__storefinder__content__wrapper__left__container__plz
	{
	///h :
	@include dc7__sass__m_selector($s_selector__base:'.om3__segment__storefinder._state__intro')
		{
		///h :
		width: 100%;
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		width: 100%;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		width: 100%;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		border: 1px solid #{$host__basic__s_color__1};
		background-color: #{$host__basic__s_color__white}; 
		width: 65%;
		display: inline-block;
		}
	}
	
///h :
.om3__segment__storefinder__content__wrapper__left__container__help
	{
	///h :
	position: relative;
	top: -25px;
	left: 0px;
	margin: 0px 0px -16px 0px;
	}
	
///h :
.om3__segment__storefinder._results__true .om3__segment__storefinder__content__wrapper__left__container__head
	{
	///h :
	display: none !important;
	}
	
///h :
.om3__segment__storefinder__content__wrapper__left__container__head
	{
	///h :
	position: relative;
	content: pinky1;
	margin: 0px 0px 1.20em 0px;
	}
        
       ///h :
.om3__segment__storefinder__content__wrapper__left__container__plz
	{
           position: relative;
        }
	 
///h :
.om3__segment__storefinder__content__wrapper__left__container__plz__lupe
	{ 
           ///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		width: 33px;
		height: auto;
		position: absolute;
	//	left: calc(65% - 40px);
                top: 3px;
                right: 5px;
        //        top: 34px;

		///h :
		@include dc7__userinput__m_mouse($s_preset_id:'button');
		}
               
	///h :
	@include dc7__sass__m_selector($s_selector__base:'.om3__segment__storefinder._state__intro')
		{
		///h :
		//left: calc(100% - 40px);
	//	top: 10px;
                  right: 5px;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
          //      top: 26px;
		width: 28px;
		right: 5px;
                top: 5px;
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		right: 5px;
                 top: 5px;
		}

	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'des')
		{
		///h :
		top: 3px !important;
		}

	}

///h :
.om3__segment__storefinder__content__wrapper__left__container__plz__input
	{
	///h :
	@include host__mx_input();

	///h :
	margin: 0px 0px 0px 0px !important;
	width: calc(100% - 70px) !important;
	border-color: transparent !important;
	background: none !important;
	
	} 

///h :
.om3__segment__storefinder__content__wrapper__left__container__radius
	{
	///h :
	@include dc7__sass__m_selector($s_selector__base:'.om3__segment__storefinder._state__intro')
		{
		///h :
		display: none;
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		width: 100%;
		margin: 20px 0px 0px 0px;
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		width: 100%;
		margin: 20px 0px 0px 0px;
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		width: 30%;
		display: inline-block;
		margin: 0px 0px 0px 20px;
		}
	}
	
///h :
html #content .om3__segment__storefinder__content__wrapper__left__container__radius__select
	{
	///h :
	height: 48px;
	border: 1px solid #{$host__basic__s_color__1} !important;
	border-color: #{$host__basic__s_color__1} !important;
	border-radius: 0px !important; 
	}

///h :
.om3__segment__storefinder__content__wrapper__left__container__button
	{
	///h :
	@include dc7__sass__m_selector($s_selector__base:'.om3__segment__storefinder._state__intro')
		{
		///h :
		display: none;
		}
	}
	
///h :
.om3__segment__storefinder__content__wrapper__right
	{
           ///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h : 
		text-align: left;
		width: 31%;

		///h : 
		@include host__mx_item();
		}
                
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		width: 100%;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		width: 100%;
		}
	
		
	///h :
	@include dc7__sass__m_selector($s_selector__base:'.om3__segment__storefinder._state__intro')
		{
		///h :
		display: none;
		}

	///h :
	@include dc7__sass__m_selector($s_selector__base:'.om3__segment__storefinder._state__intro')
		{
		}
	}
	
///h :
.om3__segment__storefinder__content__wrapper__right__img
	{
	///h :
	width: 200px;
	margin: 0px 0px 10px -15px;
	}
.om3__segment__storefinder__content__wrapper__right .p1
        {
        color: #8b2939 !important; 
        }
.om3__segment__storefinder__content__wrapper__right #p1_red
        {
        color: #8b2939 !important;  
        margin-bottom: 5px;
        }
///h :
.om3__segment__storefinder__results
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		display: inline-block;
		width: 100%;
		background-color: #{$host__basic__s_color__3};
		text-align: center;
		padding: 60px 0px 60px 0px;
		}

	///h :
	@include dc7__sass__m_selector($s_selector__base:'.om3__segment__storefinder._state__intro')
		{
		///h :
		display: none;
		}
	}
	
///h :
.om3__segment__storefinder__results__content
	{
	///h :
	width: 100%;
	max-width: 1150px;
	display: inline-block;
	text-align: left;
	}
	
///h :
.om3__segment__storefinder__results__content__result
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h : 
		width: 100.00%;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h : 
		width: 50.00%;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		@include host__mx_item();

		///h :
		padding: 10px 10px 10px 10px;
		width: 33.33%;
		background: white;
		border: 10px solid #{$host__basic__s_color__3} !important;

		///h :
		@include dc7__userinput__m_mouse($s_preset_id:'button');
		}
	}

///h :
.om3__segment__storefinder__results__content__element
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		width: 100.00%;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		width: 50.00%;
		}	
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		width: 33.33333%;
		min-height: 140px;
		min-width: 100px;
		background-clip: padding-box;
		padding: 15px 15px 15px 15px;
		text-align: left;
		display: inline-block;
		border: 10px solid transparent;
		position: relative;
		}
	}
	
///h :
.om3__segment__storefinder__results__content__element a 
	{
	text-align: left !important; 
	}
        
       ///h :
.om3__segment__storefinder__results__content__element__vfc
	{
         margin: 0px 0px 20px 0px;
        }
        
.om3__segment__storefinder__results__content__element__vfc__logo
{
    position: absolute;
    right: 0px;
    top: 10px;
}
        
        
       ///h :
.om3__segment__storefinder__results__content__element._type__normal
	{
        }
	
///h :
.om3__segment__storefinder__results__content__element__title
	{
	///h :
	margin: 0px 0px 5px 0px;
	max-width: 70%;
	}
	
///h :
.om3__segment__storefinder__results__content__element__right
	{
	///h :
	position: absolute;
	text-align: right;
	top: 10px;
	right: 10px;
	}
        
       	
///h :
.om3__segment__storefinder__results__content__element__right._type__vfc
	{
         ///h :
         top: 75px !important;
        }

///h :
.om3__segment__storefinder__results__content__element__right img
	{
	///h :
	margin: 5px 0px 5px 0px;
	}

///h :
.om3__segment__storefinder__results__content__element._type__normal
	{
	///h :
	background-color: #{$host__basic__s_color__white};
	}
.om3__segment__storefinder__results__content__element._type__vfc
	{
	///h :
	background-color: #{$host__basic__s_color__1};
	}
///h :
.om3__segment__storefinder__content__wrapper__right
	{
	///h :
	background-color: #{$host__basic__s_color__white} !important; 
	}

///h :
.om3__segment__storefinder__results__element._type__normal p1
	{
	///h :
	}
	
///h :
#content_page .om3__segment__storefinder__results__content__element._type__vfc a,
#content_page .om3__segment__storefinder__results__content__element._type__vfc a,
#content_page .om3__segment__storefinder__results__content__element._type__vfc a,
#content_page .om3__segment__storefinder__results__content__element._type__vfc a
	{
	///h :
	color: #{$host__basic__s_color__white} !important;
	}

///h :
#content_page .om3__segment__storefinder__results__content__element._type__vfc .p1,
#content_page .om3__segment__storefinder__results__content__element._type__vfc .p2,
#content_page .om3__segment__storefinder__results__content__element._type__vfc .p3,
#content_page .om3__segment__storefinder__results__content__element._type__vfc .p4
	{
	///h :
	color: #{$host__basic__s_color__white} !important;
	}
	 

	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
////////////////////////////////////!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!	
	
	
	
/*	
.step1 .sbcontent {
  background-color: transparent !important;
  text-align: center !important;
}

.step1 .sbcontent input{
	width:650px !important;
	height:90px !important;
}*/

/* apotheken finder items */
/*
.vfc-img {
  margin-right: -4px;
  margin-top: 5px;
}

._resultbox {
  background-color: #375988;
  text-align:left;
}

._resultbox .apo-datum {
  background-color: #f2f2f2;
  position: absolute;
  z-index:100;
  top: -15px;
  left: 25px;
  padding:10px;
  width: 90%;
}


._resultbox_4 {
  background-color: #375988;
  text-align:left;
}

._resultbox_2 {
  margin: 10px;
  padding: 10px 10px 0 10px;
   height: 150px; 
  display: table;
   height: 150px; 
  width: 100%;
  min-height: 160px;
  max-height: 160px;
}

._resultbox_3 {

  display: table;

  width: 100%;
}


._resultbox .apo-name {
  text-align: start;
  font-size: 18px;
  font-weight: 800;
  margin: 10px 15px 5px 9px;
  display: inline-block;
}
._resultbox .apo-distance {
  text-align: end;
  font-size: 12px;
  font-weight: 400;
  display:inline-block;
}

._resultbox .apo-gmap-target {
  text-align: right;
  margin-right: 15px;
  margin-top: -5px;
}
._resultbox .apo-adress{
  position:relative;
  top:-20px;
  width:75%;
}

._resultbox .apo-gmap-target-font {
  font-size: 12px;
}

._resultbox .apo-str {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  margin-left: 15px;
  line-height: 1.2em;
  margin: -70px 2px 5px 10px;
}

._resultbox .apo-plz {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  margin: 5px 2px 5px 10px;
  line-height: 1.2em;
}

._resultbox .apo-phone {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  margin: 5px 2px 5px 10px;
  line-height: 1.2em;
}

._resultbox .apo-fax {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  margin: 5px 2px 5px 10px;
  line-height: 1.2em;
}

._resultbox .apo-link {
  margin: -4px 2px 5px 10px;
  line-height: 1.2em;
}

._resultbox .apo-link-p {
  height: 19px;
}*/


/*.sb-canvas {
  display: inline-block;
  text-align: center;
  width: 100%;
}*/

/*

.sbcontent{
  width:90%;
  max-width: 900px;
  padding: 20px 10px 20px 14px;
  background-color: #ffffff;
  margin-top:50px;
  margin-right: auto;
  margin-bottom: 70px;
  margin-left: auto;
}

.sb-title {
  color: #375988;
}

.sb-canvas-left {
  text-align: left;
}

.sb-canvas-input-box {
  margin-top: 0px;
}

.modal-article-content {
  width: 60%;
  margin: 10px 18% 10px 20%;
}

.apo-suche-ort {
  color: red!important;
  color: #375988!important;
  font-size: 16px!important;
}
.apo-suche-ort.storefinder-alert{
  color:red!important;
}

#emergencyZip .zip {
  padding: 0px;

}

.apo-suche-umkreis-anzahl > p {
  font-family: "myriad-pro-semi-condensed", sans-serif!important;
  font-size: 13px!important;
  color: #333333!important;
  line-height: 1.30em!important;
  font-weight: normal!important;
  letter-spacing: 0px!important;
  font-style: normal!important;
  text-transform: none!important;
}

 massimos ÃƒÂ¤nderungen 

.sb-canvas .sb-canvas-left > div {
    border: 1px solid red;
    display: inline-block;
    width: 100%;
}

.sb-canvas #inputZip {
    font-size: 0px;
    line-height: 0px;
    display: inline-block !important;
    width: 100%;
}

.sb-canvas #inputZip #emergencyZip {
    color: red !important;
    line-height: 1.00em !important;
    padding: 0px 0px 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
    height: 1em !important;
    width: 40%;
    margin: 0px 5% 0px 0px;
    position: relative;
    height: 2.10rem !important;
}

.sb-canvas #inputZip > select {
    color: red !important;
    line-height: 1.00em !important;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px !important;
    width: 40%;
    height: 1em !important;
    position: relative;
}

.sb-canvas #inputZip > select > option {
   height: 1em !important;
}

.sb-canvas #inputZip > .submit {
    color: red !important;
    line-height: 1.00em !important;
    padding: 0px 0px 0px 0px;
     margin: 0px 0px 0px 0px !important;
}

.container__1 {
  display: inline-table;
  vertical-align: middle;
  padding-top: 15px;
}

.container__2 {
  display: inline-table;
  vertical-align: middle;
  padding-top: 15px;
}

.container__3 {
  display: inline-table;
  vertical-align: middle;
}


.container__3 .om3__segment__button {
  padding: 0px 0px 0px 0px;
}

._resultbox_2 p {
    line-height: 1.2em;
    font-weight: normal;
    letter-spacing: 0px;
    font-style: normal;
    text-transform: none;
}


.wj-news-tile-sub {
  border: none;
  outline: none;
  background-color: white;
}

.wj-newsrow-margin {
  margin: 10px 0px 10px 0px
}

.wj-newsrow-font {
  color: #FFFFFF;
  font-size: 20px !important;
  font-family: "myriad-pro", sans-serif;
}

.newsBackend {
  background-color: #f2f2f2 !important;
}

.newsBackend-margintop {
  margin-top:6px;
}

.wj-newsrow-border {
  border: 10px 0px 10px 0px;
}

.wj-newsrow-margin {
  margin: 10px 0px 10px 0px;
}

.wj-newsrow-font {
  color: #FFFFFF !important;
  font-size: 20px !important;
  font-family: "myriad-pro", sans-serif;
}


#newslist-btn .news-list-btn {
  margin-top: -32px;
  margin-left: -30px;
  padding: 4px;
  background-color: #375988;
  color: #FFFFFF;
}

.om3__segment__newslist_title {
  position: absolute !important;
  left: 30px;
}

.apo-src-img {
  margin-bottom: 10px;
  margin-left: 25px;
}

.apo-bg-color-display-none {
  display: none;
}

.apo-bg-color {
  width: 100%;
  text-align: center;
  background-color: #375988;
  padding-top: 75px;
}

.wj-news-tile-border .om3__segment__button {
  padding-top: 5px!important;
  padding-bottom: 0px!important;
}

.list-bg-color {
  height: 33px;
  background-color: #FFFFFF;
  color: #000000; padding: 5px;
}

._resultbox_4:hover {
  opacity: 0.6!important;
  background-color: #FFFFFF!important;
}

#om3__segment__newslist .h2 {
    margin: 50px;
}*/

///h :
._layout__loader
	{
	///h :
//	display: none;
	display: inline-block;
	position: absolute;
	z-index: 10000;
	opacity: 0.00;
	
	///h :
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'easeOutQuad');

	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'noattract');
	}
	
///h :
.neos-backend #content .neos-inline-editable
	{	
	///h :
	pointer-events: all !important;
	}
	
///h :
._layout__item
	{
	///h :
	@include host__mx_item();
	}
	

///h :
.__layout__icon,
.__layout__icon::after
	{
	background-color: transparent !important;
	}
	
///h :
.__layout__icon > img
	{
	width: 40px !important;
	height: 40px !important; 
	}
	
///h :
.dc7__modal_close
	{
	///h :
	&:hover
		{
		///h:
		@include dc7__animation__m_animation($s_id:'dc7__animation__kf_pop', $s_duration:'0.4s', $s_repeat:'1', $s_ease:'easeOutExpo', $s_delay:'0s', $b_acceleration:false, $s_direction:'forwards');
		}
	}
		
	
///h :
#content form input,
#content form textarea,
#content .om3__segment__contact input,
#content .om3__segment__contact textarea
	{ 
	///h :
	border-color: #{$host__basic__s_color__1} !important;
	border-radius: 0px !important;
	}

///h :
#content form input[type='radio']
	{
	///h : 
	width: 20px;
	text-align: left;
	}

///h :
#content .cron-formbuilder-plugin > button
	{
	///h :
	padding: 10px 20px 10px 20px;
	}

///h :
.om3__segment__image,
.permanent-image-permimage,
.permanent-image-permimage > figure,
.permanent-image-permimage-wj > figure
	{	
	///h :
	overflow: hidden;
	}
	
///h :
.om3__segment__image > a > div > figure > img,
.permanent-image-permimage-wj > figure > a > img
	{
	///h :
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'1.20s', $s_delay:'0.00s', $s_ease:'expoEaseOut');
	} 
 
///h :
.om3__segment__image > a > div > figure > img._state__hover__true,
.permanent-image-permimage-wj > figure > a > img._state__hover__true 
	{
	///h :
	@include dc7__transform__m_properties($s_scale__x:'1.05', $s_scale__y:'1.05', $s_origin__x:'50.00%', $s_origin__y:'50.00%');
	}
	
	
///h :
._fx__zoom
	{
	///h :
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'expoEaseOut');
	}
	
///h :
._fx__zoom::after
	{
	///h :
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: -1;
	content: ' '; 
	position: absolute;
	background: transparent;

	///h :
	-webkit-box-shadow: 10px 10px 49px -10px rgba(0, 0, 0, 0.00);
	-moz-box-shadow: 10px 10px 49px -10px rgba(0, 0, 0, 0.00);
	box-shadow: 10px 10px 49px -10px rgba(0, 0, 0, 0.00);

	///h :
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'expoEaseOut');
	} 
 
///h :
._fx__zoom:hover
	{
	///h :
	@include dc7__transform__m_properties($s_scale__x:'1.03', $s_scale__y:'1.03', $s_origin__x:'50.00%', $s_origin__y:'50.00%');
	}
	
///h :
._fx__zoom:hover::after
	{
	///h :
	-webkit-box-shadow: 10px 10px 49px -10px rgba(0, 0, 0, 1.00);
	-moz-box-shadow: 10px 10px 49px -10px rgba(0, 0, 0, 1.00);
	box-shadow: 10px 10px 49px -10px rgba(0, 0, 0, 1.00);
	}

///h :
html.dc7__modal__bs_in_modal__true .content
	{
	///h :
	top: 0px !important;
	}
	
///h :
.dc7__modal__icon .__layout__icon
	{
	///h :
	top: 0px !important;
	}
	
.dc7__modal_close
	{
	///h :
	top: 20px;
	right: 16px;
	background: #{$host__basic__s_color__1__transparent} !important;
	border-radius: 50%; 
	height: 60px;
	width: 60px;
	}
	
.dc7__modal_close > img
	{
	///h :align-content: 
	width: 40px !important;
	height: 40px !important;
	position: absolute;
	left: 10px;
	}

///h :
.parax
	{
	///h :
	background-attachment: fixed;
	background-position: center center;
	background-size: cover;
	}

///h :
.bottomIcon
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		position: fixed;
		bottom: -2px;
		left: 10%;
		z-index: 600;
		border: 1px solid white !important;
		}
		
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		display: none !important;
		}
	}

///h :
.dc7__modal__bs_in_modal__true .bottomIcon
	{
	///h :
	display: none !important;	
	}
	
///h :
.dc7__modal .newssection.frontend
	{
	///h :
	margin-top: 0px !important;
	}

///h :
html .content_page
	{
	///h :
	//padding: 120px 0px 0px 0px;
	}


///h :
html .dc7__modal .content_page
	{
	///h :
	padding: 0px 0px 0px 0px !important;
	}

///h :
.content .content_page .om3__segment__text  p > a,  
.content .content_page .om3__segment__html  p > a,
.content .content_page .om3__segment__magazine_element_content  p > a
	{
	///h :
	text-decoration: underline !important;
	position: relative;
	margin: 0px 0px 0px 0.40em;
	display: inline-block;
	}
	
///h :
.content .content_page .om3__segment__text  p > a:hover,  
.content .content_page .om3__segment__html  p > a:hover,
.content .content_page .om3__segment__magazine_element_content  p > a:hover
	{
	///h :
	text-decoration: none !important;
	}

///h :
.content .content_page .om3__segment__text p > a:before,  
.content .content_page .om3__segment__html p > a:before,
.content .content_page .om3__segment__magazine_element_content p > a:before
	{
	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'noattract');

	///h :
	font-size: 0.50em;
	position: relative;
	left: -0.80em;
	position: absolute;
	font-family: 'FontAwesome';
	content: '\f054';
	top: 1.00em;
	letter-spacing: -0.28em;
	line-height: 1.00em;
	}
	


///h : 
.dc7__modal
	{
//	overflow: hidden !important;
	}
	
///h : 
.dc7__modal__bs_in_modal__true .content_page > .neos-contentcollection > .om3__segment__clip
	{
	///h : d
	top: 0px !important;
	}
	
///h :
.dc7__modal_content_page
	{
	///h :
//	overflow: hidden !important;
	}

///h :
html.__bs_modal__true 
	{
	///h :
//	position: fixed; 
//	width: 100%; 
//	//max-height: 100%;
//	overflow: hidden !important;
//	overflow-y: scroll !important;
	}
	
///h :
html.__bs_modal__true .dc7__modal
	{
	///h :
//	overflow-y: scroll !important;
	}
	
///h :
.__bs_backgroundparallax__true
	{
	///h :
//	overflow: hidden;
/*	background-size: auto; 
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.00s', $s_delay:'0.00s', $s_ease:'linear');
	background-size: 150% 150% !important;*/
	}
	
	
///h :
._layout__background_image
	{
	///h :
	width: 150%;
	height: 150%;
	position: absolute;
	left: 0px;
	top: 0px;
	opacity: 0.50;
	left: -25%;
	top: -25%;
	background-size: cover;
	background-repeat: no-repeat;
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : loader

///h :
$offset: 187;
$duration: 1.40s;

///h :
.spinner
	{
	///h :
	animation: rotator $duration linear infinite;
	
	///h :
	@include dc7__transform__m_properties($s_scale__x:'0.70', $s_scale__y:'0.70', $s_origin__x:'50.00%', $s_origin__y:'50.00%');
	}

///h :
@keyframes rotator
	{
	///h :
	0%	{transform: rotate(0deg)}
	100%	{transform: rotate(270deg)}
	}

///h :
.path
	{
	///h :
	stroke-dasharray: $offset;
	stroke-dashoffset: 0;
	transform-origin: center;
	animation: dash $duration ease-in-out infinite, colors ($duration*4) ease-in-out infinite;
	}

///h :  
@keyframes colors
	{
	///h : 
	0%	{stroke: #4285F4;}
	50%	{stroke: #333333;}
	99%	{stroke: #4285F4;}
	}

///h : 
@keyframes dash
	{
	0%	{stroke-dashoffset:$offset}
	50%	{stroke-dashoffset: $offset/4; transform:rotate(135deg)}
	100%	{stroke-dashoffset: $offset; transform:rotate(450deg);}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : modal

///h :
.dc7__modal__bs_in_modal__true .fader .spinner
	{
	///h :
	display: none !important;
	}

///h :
.dc7__modal
	{
	///h :
	overflow: hidden !important;
	//overflow-y: scroll !important;
	background-color: rgba(255, 0, 0, 0.5) !important;
	-webkit-overflow-scrolling: touch !important;
	}

///h :
html.__bs_modal__true
	{
	///h :
/*	overflow: hidden;
	overflow-y: hidden;
	height: 100vh;
	-webkit-overflow-scrolling: auto !important*/
	}

///h :
html.__bs_modal__true > body
	{
/*	overflow: hidden !important;
	position: absolute !important;
	-webkit-overflow-scrolling: auto !important;  */
	}

///h : 
.dc7__modal_content
	{
/*	D
  	overflow-y: scroll !important;
	position: absolute;
	height: auto;*/
/*
	height: 100%;
	overflow: scroll;*/
	}
	
///h :
.dc7__grid__s_type__smartphone .dc7__modal_content_page__iframe,
.dc7__grid__s_type__tablet .dc7__modal_content_page__iframe
	{
	///h :  
	overflow-y: scroll !important;
	}
	
	
.dc7__grid__s_type__desktop  .dc7__modal_content_page
	{
//	overflow-y: hidden !important;
	}
	
///h :
.dc7__modal_content_page_content
	{
	///h :  
//	overflow-y: scroll !important;
	}

///h :
.dc7__grid__s_type__desktop .dc7__modal_content_page_content
	{
//	overflow-y: scroll !important; 
	}
	
///h :
.dc7__grid__s_type__smartphone .dc7__modal_content,
.dc7__grid__s_type__tablet .dc7__modal_content 
	{
	overflow-y: scroll !important;
	}
	
///h :
.dc7__grid__s_type__smartphone .dc7__modal,
.dc7__grid__s_type__tablet .dc7__modal  
	{
/*	opacity: 1.00 !important; 
	-webkit-overflow-scrolling: auto;
	color: pinky;*/
	}
	
	
	
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : wanja css

.ql-btn-wanja:hover {
    background-color: #FFFFFF;
    color: #375988 !important;
}

#content_page .bluewhite {
    background-color: #375988!important;
    color: white!important;
}

#content_page .whiteblue {
    background-color: #FFFFFF!important;
    color: #375988!important;
}

.quicklink-btn-bw {
    background-color: #375988;
    color: white;
}


.quicklink-btn-wb {
    background-color: #FFFFFF;
    color: #375988 !important;
}

#content_page .quicklink-margin {
    border: 10px transparent solid;
}

.om3__segment__newslist .h2 {
    margin: 50px;
}

.om3__segment__newslist_elements_item {
    margin-top: 50px;
}

.om3__segment__newslist {
    background-color: #f2f2f2;
}

li.om3__segment__newslist_elements_item {
    background-color: white;
    padding-top: 20px;
}

h2.om3__segment__newslist_elements_item {
    float:left !important;
}

a.dc7__behavior__acall {
    text-align: center;
}

.news-teaser {
    text-align: left;
}

.be-news-image {
    max-width: 100%;
    height: auto;
    overflow:  hidden;
}

.newsrow-text-style.backend .newsrow {
    color: #FFFFFF;
}

#content_page .content_page {
    max-width: 100vw;
    overflow-x: hidden;
    background-color: #375988;
}



.wr-article-block {
    display: block;
}

.om3__segment__newslist__small {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  background-clip: padding-box;
  width: calc(100% * 3 / 12);
}

.om3__segment__newslist__large {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  background-clip: padding-box;
  width: calc(100% * 6 / 12);
}

.news-list-margin-top {
  margin-top: 200px;
}

.permanent-image-permimage-wj {
  width: 100%;
}

.newslist-img figure img {
  max-width: 100%;
  height: auto;
}

img.news-teaser-img {
  width: auto!important;
}

.newslist__top__padding {
  padding-top: 85px;
}

.article-title {
  margin: 20px;
}

.article-img {
  margin: 20px;
}

.article-content {
  margin: 40px;
}

p.news-teaser {
    font-family: "myriad-pro", sans-serif;
    /*font-size: 12px;*/
    /*line-height: 1.40em;
    font-weight: normal;
    letter-spacing: 0px;
    font-style: normal;
    text-transform: none;*/
}

.p-tag-style-wj > p {
  /*font-family: "myriad-pro", sans-serif;
  font-size: 12px !important;
  color: #333333;
  line-height: 1.40em;
  font-weight: normal;
  letter-spacing: 0px;
  font-style: normal;
  text-transform: none;*/
  text-align: left;
}

.apt-finder-btn {
  white-space: nowrap;
}

.zip input[type=text] {
    padding:5px;
    border:2px solid #375988;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.zip input[type=text]:focus {
    border-color:#375988;
}

.zip input[type=submit] {
    padding:5px 15px;
    background:#375988;
    border:0 none;
    cursor:pointer;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}


.p-tag-style-wj p {
  /*color: #375988!important;*/
}

.input-zip-tb {
  display: inline-block;
  width: 230px;
  /*margin-right: 5px;
  min-width: 160px;
  margin-top: -14px;
  padding-left: 6px!important;*/
}


/* news section section */

.nl-image-width {
 width: 100%;
 height: auto;
}

.om3__segment__newslist .date-wj {
  background-color: #FFFFFF;
}

.om3__segment__newslist .date-wj-p {
  text-align: right;
  font-size: 13px!important;
  padding: 3px 5px 3px 3px;
  color: #375988;
}

.om3__segment__newslist .news-segment-text-wj {
  margin-top:0px;
  margin-right:0px;
  margin-bottom:0px;
  margin-left:0px;
  padding: 0px 20px 7px 20px;
  border: none;
  outline: none;
  text-align: right;
}

.om3__segment__newslist .news_more_button {


}

.om3__segment__newslist .p-tag-style-wj > p {
  /*font-family: "myriad-pro", sans-serif;
  font-size: 12px !important;
  color: #333333;
  line-height: 1.40em;
  font-weight: normal;
  letter-spacing: 0px;
  font-style: normal;
  text-transform: none;*/
}

.om3__segment__newslist .p-tag-style-wj p {
  /*color: #375988!important;*/
}

.om3__segment__newslist .p-tag-style-wj {
  /*text-align: left;
  font-size: 15px;*/
  /*color: #375988!important;*/
}

.om3__segment__newslist > h2{
  position:absolute;
}

.om3__segment__newslist .news-title h2 {
    text-align: left;
    line-height: 120%;
    /*color: #375988;*/ /*problem*/
    font-size: 18px;
}

.om3__segment__newslist button.news-list-button {
  font-size: 10px;
  width: 85px;
  background-color: #FFFFFF!important;
  color: #375988 !important;
  float: right;
  margin-bottom:
  4px; font-size:
  10px; width: 85px;
}

.om3__segment__newslist button.news-list-button:hover {
  font-size: 10px;
  width: 85px;
  background-color: #375988!important;
  color: #FFFFFF !important;
}

.permanent-image-permimage-wj img {
  width: 100%;
  height: auto;
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : wanja newslist



.newssection{
    margin-top:20px;
}

.news-list-block-ul{
    justify-content: left !important;
}
.news-title{
    text-align: left;
}

.newssection.frontend{
}
.newssection.frontend h1{
    color: #009fde;
}
.newsListBox{
    border:5px solid transparent !important;
    background-color:#ffffff;
    background-clip:padding-box;
}

///h :
._fx__bparallax
	{
	///h :
	background-attachment: fixed;
	background-position: center center;
	background-size: cover;
/*	background-repeat: no-repeat !important;*/
	}

///h :
.content_footer a
	{
	///h :
	margin: 0.30em 0.30em 0.30em 0.30em;
	}
	
///h :
.dc7__behavior__acall > .permanent-image-permimage
	{
	///h :
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'expoEaseInOut');

	///h :
	@include dc7__transform__m_properties($s_scale__x:'1.00', $s_scale__y:'1.00', $s_origin__x:'50.00%', $s_origin__y:'50.00%');
	}
	
///h : 
.dc7__behavior__acall > .permanent-image-permimage:hover
	{
	///h :
	@include dc7__transform__m_properties($s_scale__x:'1.05', $s_scale__y:'1.05', $s_origin__x:'50.00%', $s_origin__y:'50.00%');
	}
	
///h :
._layout__bpimage
	{
	///h :
	position: absolute;
	top: -50%;
	left: 0px;
	width: 100%;
	height: 150%;
	background-size: cover;
	background-repeat: no-repeat;
	outline: 1px solid transparent !important; 

	///h :
	//@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.80s', $s_delay:'0.00s', $s_ease:'expoEaseOut');
	}
	
///h :
._layout__bpimage > img
	{
	///h : 
	opacity: 0.00;
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : nform
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :
.om3__segment__nform
	{
	///h :
	display: inline-block;
        text-align: left;
	width: 100%;
	}

///h :
.om3__segment__nform > fieldset > .om3__segment__nform__element,
.om3__segment__nform > fieldset > div
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		padding: 5px 30px 5px 30px;
		margin: 0px 0px 0px 0px;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		padding: 5px 10px 5px 10px;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		padding: 5px 20px 5px 20px;
		}
	}
	
///h :
.om3__segment__nform > fieldset > div:last-child
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		border-bottom: none !important;
		}
	}

///h :
.om3__segment__nform .required
	{
	///h :
	margin: 0px 0px 0px 0.20em;
	position: relative;
	top: -2px;
	font-size: 1.20em;
	color: #{$host__basic__s_color__warning} !important;
	}

///h :
.om3__segment__nform .help-inline
	{
	///h :
	font-size: 0.70em;
	margin: -0.70em 0px 0px 0px;
	color: #{$host__basic__s_color__warning} !important;
	}

///h :
.om3__segment__nform .help-inline button
	{
	///h :

	}

///h :
.om3__segment__nform label,
.om3__segment__nform h3
	{
	///h :
	@extend h4;

	///h :
	font-size: 1.10em !important;
	margin: 0px 0px 0.20em 0px;
	}

///h :
.om3__segment__nform  fieldset > h2
	{
	///h :
	display: none;
	}

///h :
.om3__segment__nform button
	{
	///h :
	width: auto;
	display: inline-block;
	padding: 8px 20px 11px 20px !important;
	}

///h :
.om3__segment__nform label
	{
	///h :
	pointer-events: all;
	}

///h :
.om3__segment__nform input[type='radio'],
.om3__segment__nform input[type='checkbox']
	{
	///h : 
	cursor: pointer;
	padding: 0px 0px 0px 0px !important;
        margin: 0px 0px 0px 0px !important;
	}
	
///h :
._apple__true .om3__segment__nform input[type='radio'],
._apple__true .om3__segment__nform input[type='checkbox']
	{
	///h : 
	cursor: pointer;
	padding: 0px 5px 5px 0px !important;
        margin: 0px 5px 0px 0px !important;
	}
	
///h :
.om3__segment__nform li
	{
	///h :
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
	}
	
///h :
.om3__segment__nform li label
	{
	///h :
	margin: 0px 0px 0px 0px !important;
	padding: 0px 0px 0px 0px !important;
        min-height: 1.6em;
        line-height: 2px !important;
        
	}
	
///h :
.om3__segment__nform li label > input
	{
	///h :
	margin: 0px 0px -10px 0px;
	}

///h :
.om3__segment__nform input[type='date'],
.om3__segment__nform ._type__datepicker
	{
	///h :
	border-color: #375988 !important;
	border-radius: 0px !important;
	padding: 0.75em 0.25em 0.75em 0.25em !important;
	}

///h :
.om3__segment__nform .inputs-list > li
	{
	///h :
	//margin-bottom: -13px;
	position: relative;
	}
	
///h :
.om3__segment__nform .om3__segment__nform__element  
	{
	///h :
	top: -13px !important;
	bottom: -26px !important;
	position: relative;
	
	}
	
///h :
.om3__segment__nform__element._type__checkbox
	{
	///h :

	}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : nform - checkbox
	
///h :
.om3__segment__nform .om3__segment__nform__element._type__checkbox
	{
	///h : 
	}
	


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : nform - checkbox
	
///h :
.om3__segment__nform .submit
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :		
		margin: 0px 0px 0px 30px;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		margin: 0px 0px 0px 10px;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'tab')
		{
		///h :
		margin: 0px 0px 0px 20px;
		}
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : backgroundparallax

///h :
.dc7__behavior__backgroundparallax
	{
	///h :
	position: absolute;
	overflow: hidden;
	}

///h :
.dc7__behavior__backgroundparallax._init__true
	{
	///h :
	background-image: none !important;
	}

///h : 
.dc7__behavior__backgroundparallax__image
	{
	///h :
	@include dc7__layout__m_flexbox($s_horizontal:'center', $s_vertical:'center');

	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'none');

	///h :
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100.50%;
	height: 101.50%;
	background-color: transparent;
	z-index: -1;
	opacity: 0.90;
	}

///h : 
.dc7__behavior__backgroundparallax__image__img
	{
	///h :
	position: absolute;
	width: 150.00%;
	height: 150.00%;
	opacity: 1.00;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	z-index: -2;
	top: 0px;
	left: 0px;
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : share
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :
._item__share
	{
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'def')
		{
		///h :
		@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.30s', $s_delay:'0.00s', $s_ease:'easeOutQuad');

		///h :
		position: fixed;
		right: 30px;
		top: 65px;
		background: transparent; 
		margin: 0px 0px 40px 0px;
		z-index: 510;
		text-align: right;
		}
	///h :
	@include dc7__sass__m_selector($s_breakpoint_id:'sma')
		{
		///h :
		right: 15px;
		top: 55px;
		}
	}
	
///h :
._item__share._active__true
	{
	///h :
	z-index: 1510;
	}

///h :
._menucompact__1 ._item__share
	{ 
	///h :
	@include dc7__transform__m_properties($s_position__y:'10px');
	}
	
///h :
._menucompact__2 ._item__share
	{ 
	///h :
	@include dc7__transform__m_properties($s_position__y:'80px');
	}

///h :
._item__share__content
	{
	///h :
	@include host__mx_item();

	///h :
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.20s', $s_delay:'0.00s', $s_ease:'easeOutQuad');

	///h :
	position: absolute !important;
	top: -13px;
	right: 26px;
	background-color: #{$host__basic__s_color__white}; 
	border-width: 8px !important;
	border-right: 20px solid white;
	border-left: 20px solid white;
	width: 315px;
	max-width: calc(100vw - 23px);
	z-index: 1025;
	text-align: left;
	opacity: 0.00;
	overflow: hidden;
	height: 67px;
	}

///h :
._item__share._active__true ._item__share__content
	{
	///h :
	opacity: 1.00;
	}

///h :
._item__share__content > p
	{
	///h :
	color: #{$host__basic__s_color__1};
	font-size: 14px !important;
	}

///h :
._item__share__content__text
	{
	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'none');
	}

///h :
._item__share__content__copy
	{
	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'button');

	///h :
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.20s', $s_delay:'0.00s', $s_ease:'easeOutQuad');

	///h :
	background-color: #{$host__basic__s_color__1};
	color: #{$host__basic__s_color__white} !important;
	display: inline-block;
	border-radius: 2px;
	padding: 1px 6px 3px 8px;
	position: relative;
	margin-left: 6px;
	opacity: 1.00% !important;
	top: 0px;
	vertical-align: text-bottom !important;
	}
 
///h :
._item__share__content__copy:hover
	{
	///h :
	background-color: #{$host__basic__s_color__1__dark} !important;
	}

///h :
._item__share__content__uri
	{
	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'default');

	///h :
	margin: 3px 0px 0px 0px;
	background-color: #F2F2F2;
	padding: 4px 10px 4px 4px;
	display: inline-block;
	cursor: text;
	text-overflow: ellipsis;
	max-width: calc(100% - 30px);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 220px;
	}
	
///h :
._item__share__button
	{
	///h :
	@include dc7__userinput__m_mouse($s_preset_id:'button');
	
	///h :
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'easeOutQuad');

	///h : 
	z-index: 1026;
	position: relative;
	border-radius: 50.00%;
	position: relative;
	top: 0px;
	left: 3px;
	background-color: rgba(255, 255, 255, 0.00);
	border: 1px solid rgba(255, 255, 255, 0.00);

	///h :
	&:hover
		{
		///h:
		@include dc7__animation__m_animation($s_id:'dc7__animation__kf_pop', $s_duration:'0.30s', $s_repeat:'1', $s_ease:'easeOutExpo', $s_delay:'0.00s', $b_acceleration:false, $s_direction:'forwards');
		}
	}

///h :
._item__share._active__true ._item__share__button
	{
	///h :
	top: -13px;
	border: 21px solid rgba(255, 255, 255, 1.00);
	background-color: rgba(255, 255, 255, 1.00);
	}
	
///h :
._item__share__button__img
	{
	///h :
	width: 25px;
	height: 25px;
	}
	
	
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : cookie
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :
.dc7__modal__bs_in_modal__true .cc-window
	{
	///h :
	display: none !important;
	visibility: hidden !important;
	}

///h :
.cc-window
	{
	///h :
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.60s', $s_delay:'0.40s', $s_ease:'easeOutQuad');

	///h :
	background-color: #{$host__basic__s_color__1__dark} !important;
	font-size: 14px !important;
	border-top: 1px solid #{$host__basic__s_color__white} !important;
	bottom: -70px !important;
	width: 100.00vw !important;
	}

///h : 
.cc-window._active__true
	{
	///h :
	bottom: 0px !important;
	}

///h : 
.cc-window .cc-btn
	{
	///h :
	padding: 8px 8px 8px 8px !important;
	font-size: 14px !important;
	}

///h : 
.cc-window .cc-link
	{
	///h :
	font-weight: bold !important;
	}

///h :
.cc-window .cc-compliance .cc-dismiss
	{
	///h :
	@include dc7__animation__m_transition($s_property_name:'all', $s_duration:'0.40s', $s_delay:'0.00s', $s_ease:'easeOutQuad');

	///h : 
	background-color: #{$host__basic__s_color__1} !important;
	position: relative;
	top: 1px;
	}

///h :
.cc-window .cc-compliance:hover .cc-dismiss
	{
	///h :
	color: #{$host__basic__s_color__black} !important;
	background-color: #{$host__basic__s_color__white} !important;
	}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : textlinks
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	.om3__segment__menu .om3__segment__menu_navigation {
		height: 100px;
		align-items: center;
	}
	.om3__segment__menu_navigation{
		max-width: 1150px;
		width: 100%;
		height: 100px;
		transform-origin: 0px 0px;
		transform: skewX(0deg) skewY(0deg) scale(1.0, 1.0) rotate(0deg) translate(0px, 30px);
		z-index: 2000;
	}

	/* detect firefox and add body class to hide in page anchor links in Firefox ( do not work in BOB iframes  )*/

	.firefox{
		.is-anchor-link{
			display:none !important;
		}
	}

	.safari{
		.is-anchor-link{
			display:none !important;
		}
	}

	.arrow-pagescroll-up{
		position: fixed;
		color: #fff;
		cursor: pointer;
		bottom: 25px;
		right: 37px;
		z-index: 1010;
		text-align: center;
		transition-property: all;
		transition-duration: .4s;
		transition-timing-function: cubic-bezier(.19,1,.22,1);
		transition-delay: 0ms;
	}

	// Feedback Message
	// https://www.belsana-apotheken.de/de/belsana-online-bestellen-login/?err=register_success
   .success-box{
	   font-size:36px !important;
	   padding: 1rem !important;
	   border: 2px solid #34A900 !important;
	   max-width: 1150px !important;
	   width: 100%;
	   margin:1rem auto;
	   color: #34A900 !important;
	   background-color: white ;
   }