/// Remove all trailing and leading whitespaces from `$string`.
/// @since 1.2.0
/// @param {String} $string - string
/// @return {String}
@function _ss-str-trim($string) {
  $start: 1;
  $end: str-length($string);

  @for $i from 1 through str-length($string) {
    $first: str-slice($string, $i, $i);
    $last: str-slice($string, -$i, -$i);

    @if $first == " " and $i + 1 == $start + 1 {
      $start: $i + 1;
    }

    @if $last == " " and str-length($string) - $i == $end - 1 {
      $end: str-length($string) - $i;
    }
  }

  @return str-slice($string, $start, $end);
}
