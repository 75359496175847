@function str-last-index($string, $needle) {
  @if type-of($string) != "string" {
    @error "`str-last-index` function expecting a string for $string; #{type-of($string)} given.";
  }

  @if type-of($needle) != "string" {
    @error "`str-last-index` function expecting a string for $needle; #{type-of($needle)} given.";
  }

  @return _ss-str-last-index($string, $needle);
}
