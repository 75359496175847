/// Replace occurrences of `%s` in `$string` by `$elements`.
/// @since 1.2.0
/// @param {String}  $string   - string to check
/// @param {Arglist} $elements - strings to use for replacements in `%s`
/// @return {String}
@function _ss-str-printf($string, $elements...) {
  $breaker: "%s";
  $result: $string;

  @each $element in $elements {
    $index: str-index($result, $breaker);
    @if $index {
      $result: str-slice($result, 1, $index - 1) + _ss-stringify($element) + str-slice($result, $index + str-length($breaker));
    } @else {
      @return $result;
    }
  }

  @return $result;
}
