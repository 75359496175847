/// Implode `$list` into a string.
/// @since 1.2.0
/// @param {List} $list - list to convert to string
/// @return {String}
@function _ss-str-implode($list) {
  $result: "";

  @each $item in $list {
    $result: $result + if(length($item) > 1, str-implode($item), $item);
  }

  @return $result;
}
