@function str-rot($string, $rot: 13) {
  @if type-of($string) != "string" {
    @error "`str-rot` function expecting a string for $string; #{type-of($string)} given.";
  }

  @if type-of($rot) != "number" {
    @error "`str-rot` function expecting a number for $rot; #{type-of($rot)} given.";
  }

  @return _ss-str-rot($string, $rot);
}
